<template>
    <div>
        <div style="float: left"><h4>Dashboard</h4></div>
        <div style="float: right; margin-bottom: 20px;"><b-form-select v-model="selectedMarket" :options="markets"></b-form-select></div>
        <div style="clear: both" class="mt-3">
            <b-card-group deck>
                
                <b-card
                    border-variant="black"
                    header-bg-variant="secondary"
                    header-text-variant="white"
                    bg-variant="light"
                    header="light"
                >
                    <template v-slot:header>
                        <h5 class="mb-0"><b>Participants on-site</b></h5>
                    </template>
                    <b-card-text>
                        <div style="float: left">Total</div>
                        <div style="float: right">Market</div>
                        <div style="clear: both">
                            <b-list-group horizontal>
                                <b-list-group-item style="width: 50%; text-align: center;"><b style="font-size: 40px;">{{ totalUserCount }}</b></b-list-group-item>
                                <b-list-group-item style="width: 50%; text-align: center;"><b style="font-size: 40px;">{{ filterUserCount }}</b></b-list-group-item>
                            </b-list-group>
                        </div>
                    </b-card-text>
                </b-card>

                <b-card
                    border-variant="black"
                    header-bg-variant="secondary"
                    header-text-variant="white"
                    bg-variant="light"
                    header="light"
                >
                    <template v-slot:header>
                        <h5 class="mb-0"><b>Passed Training Stations</b></h5>
                    </template>
                    <b-card-text>
                        <div style="float: left">Total</div>
                        <div style="float: right">Market</div>
                        <div style="clear: both">
                            <b-list-group horizontal>
                                <b-list-group-item style="width: 50%; text-align: center;"><b style="font-size: 40px;">{{ totalPassedCount }}</b></b-list-group-item>
                                <b-list-group-item style="width: 50%; text-align: center;"><b style="font-size: 40px;">{{ filterPassedCount }}</b></b-list-group-item>
                            </b-list-group>
                            <b-list-group horizontal>
                                <b-list-group-item style="width: 50%; text-align: center;"><b style="font-size: 40px;">{{ totalPassedPercent() }}</b>%</b-list-group-item>
                                <b-list-group-item style="width: 50%; text-align: center;"><b style="font-size: 40px;">{{ filterPassedCount }}</b>%</b-list-group-item>
                            </b-list-group>
                        </div>
                    </b-card-text>
                </b-card>

            </b-card-group>
            
        </div>
        <div style="clear: both" class="mt-3">
            <b-card-group deck>
                
                <b-card
                    border-variant="black"
                    header-bg-variant="secondary"
                    header-text-variant="white"
                    bg-variant="light"
                    header="light"
                >
                    <template v-slot:header>
                        <h5 class="mb-0"><b>Training Station Rating</b></h5>
                    </template>
                    <b-card-text>
                        <b-table
                            small
                            responsive
                            striped
                            :items="stationRating"
                            :fields="stationRatingFields"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                        >
                            <template v-slot:cell(rank)="data">
                                {{ data.index + 1 }}
                            </template>
                            <template v-slot:cell(name)="data">
                                <b>{{ data.item.name }}</b>
                            </template>
                            <template v-slot:cell(average)="data">
                                {{ data.item.average }} / 5
                            </template>
                            <template v-slot:cell(votes)="data">
                                {{ data.item.votes }} / {{ totalUserCount }}
                            </template>
                            <template v-slot:cell(percent)="data">
                                {{ data.item.percent }}%
                            </template>
                        </b-table>
                    </b-card-text>
                </b-card>

            </b-card-group>
            
        </div>
            <!-- <b-card
                title="participants on-site"
                tag="article"
                style="max-width: 20rem;"
                class="mb-2"
            >
            <b-card-text>
                <b-list-group horizontal="md">
                    <b-list-group-item class="flex-fill"><b>{{ totalCount }}</b></b-list-group-item>
                    <b-list-group-item class="flex-fill"><b>{{ filterCount }}</b></b-list-group-item>
                </b-list-group>
            </b-card-text>
            </b-card> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            intervall: "",
            updateIsActive: true,
            selectedMarket: null,
            markets: [],
            totalUserCount: 0,
            filterUserCount: 0,
            totalPassedCount: 0,
            filterPassedCount: 0,
            stationCount: 7,
            stationRating: [],
            sortBy: "average",
            sortDesc: true,
            stationRatingFields: [
                { key: 'rank', label: 'Rank' },
                { key: 'name', label: 'Station' },
                { key: 'average', label: 'Average' },
                { key: 'votes', label: 'Users' },
                { key: 'percent', label: 'Users percent' },
                // { key: 'average', label: 'Total Average' },
                // { key: 'votes', label: 'Total Users' },
                // { key: 'averageMarket', label: 'Market Average' },
                // { key: 'votesMarket', label: 'Market Users' },
            ],
            usersCount: ""
        }
    },
    methods: {
        startTimer() {
            this.intervall = setInterval(function() {
                if (this.updateIsActive) {
                    this.refreshDashbaord()
                }
            }.bind(this), 10000);
        },
        refreshDashbaord() {
            this.kmo_refreshMarkets().then(() => {
                this.markets = []
                this.markets.push({ value: null, text: "All" },)
                this.$store.markets.forEach(markt => {
                    this.markets.push({ value: markt['countryKey'], text: markt['name'] },)
                })
            }),
            this.kmo_refreshUserCount().then(() => {
                this.usersCount = this.$store.usersCount
                this.totalUserCount = 0
                this.usersCount.forEach(count => {
                    this.totalUserCount += parseInt(count['count'])
                });
            })
            this.kmo_refreshPassedCount().then(() => {
                this.passedCount = this.$store.passedCount
                this.totalPassedCount = 0
                this.passedCount.forEach(count => {
                    this.totalPassedCount += parseInt(count['count'])
                });
            })
            this.kmo_refreshStationRating().then(() => {
                this.stationRating  =[];
                this.$store.stationRating['elements'][0]['values'].forEach(rating => {
                    switch (rating['id']) {
                        case "1": rating['name'] = "Leon Experience"; break;
                        case "2": rating['name'] = "Competitor Experience"; break;
                        case "3": rating['name'] = "Connectivity"; break;
                        case "4": rating['name'] = "Assistance Systems"; break;
                        case "5": rating['name'] = "Go electric!"; break;
                        case "6": rating['name'] = "Leon Accessories"; break;
                        case "7": rating['name'] = "Leon Sportstourer"; break;
                        default: break;
                    }
                    // rating['averageMarket'] = 0
                    // rating['votesMarket'] = 0
                    rating['average'] = parseFloat(rating['average']).toFixed(1)
                    rating['percent'] = parseFloat((parseFloat(rating['votes']) * 100) / this.totalUserCount).toFixed(1)
                    this.stationRating.push(rating)
                });
            })
        },
        totalPassedPercent() {
            var percent = parseFloat((this.totalPassedCount * 100) / (this.totalUserCount * this.stationCount)).toFixed(1)
            if (isNaN(percent)) {
                return 0
            } else {
                return percent
            }
        }
    },
    created() {
        this.updateIsActive = true
        this.refreshDashbaord()
        this.startTimer()
    },
    beforeDestroy() {
        this.updateIsActive = false
    }
}
</script>

<style>

</style>