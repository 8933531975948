<template>
    <div>
        <div><h4>Gespielte Stationen</h4></div>
        <Bar
            id="chart"
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
        /><br />
        <b-list-group style="float: right; width: 280px; margin-bottom: 20px;" class="small">
            <b-list-group-item class="d-flex justify-content-between align-items-center py-2">
                Die Planung
                <b-badge id="color" variant="primary" pill>{{ passedCount[0] }}</b-badge>
            </b-list-group-item>

            <b-list-group-item class="d-flex justify-content-between align-items-center py-2">
                Die Produkte
                <b-badge id="color" variant="primary" pill>{{ passedCount[1] }}</b-badge>
            </b-list-group-item>

            <b-list-group-item class="d-flex justify-content-between align-items-center py-2">
                Die Installationsunterstützung
                <b-badge id="color" variant="primary" pill>{{ passedCount[2] }}</b-badge>
            </b-list-group-item>

            <b-list-group-item class="d-flex justify-content-between align-items-center py-2">
                Mission Wärmewende
                <b-badge id="color" variant="primary" pill>{{ passedCount[3] }}</b-badge>
            </b-list-group-item>

            <b-list-group-item class="d-flex justify-content-between align-items-center py-2">
                Gesamt
                <b-badge id="sum" variant="primary" pill>{{ passedCount[0]+passedCount[1]+passedCount[2]+passedCount[3]+passedCount[4] }}</b-badge>
            </b-list-group-item>
        </b-list-group>
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'BarChart',
    components: {
        Bar
    },
    props: {
        chartId: {
            type: String,
            default: 'bar-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {}
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            passedList: [],
            timer: '',
            loaded: false,
            passedCount: [0, 0, 0, 0, 0],
            chartData: {},
            chartOptions: {
                responsive: true, 
                maintainAspectRatio: false,
                scales: {
                    y: {
                        title: {
                            color: 'black',
                            display: true,
                            text: 'Anzahl'
                        }
                    }
                }
            }
        }
    },
    created() {
        this.refreshPassed()
        this.timer = setInterval(this.refreshPassed, 10000)
    },
    beforeDestroy() {
        this.cancelAutoUpdate()
    },
    methods: {
        cancelAutoUpdate () {
            clearInterval(this.timer)
        },
        refreshPassed() {
            this.loaded = false
            this.kmo_refreshPassed().then(() => {
                this.passedList = this.$store.passedCount
                this.passedList.forEach(passed => {
                    switch (passed['stationGroup']) {
                        case "challenge-1": this.passedCount[0] = passed['count']; break;
                        case "challenge-2": this.passedCount[1] = passed['count']; break;
                        case "challenge-3": this.passedCount[2] = passed['count']; break;
                        case "challenge-4": this.passedCount[3] = passed['count']; break;
                        default: break;
                    }
                })
                this.chartData = {
                    labels: [
                        'Die Planung',
                        'Die Produkte',
                        'Die Installationsunterstützung',
                        'Mission Wärmewende'
                    ],
                    datasets: [
                        {
                            label: 'Anzahl',
                            backgroundColor: '#AA6C43',
                            data: this.passedCount
                        }
                    ]
                }
                this.loaded = true
            })
        }
    }
}
</script>

<style>
#chart {
    background-color: white;
}
#color {
    background-color: #AA6C43;
    font-size: 13px;
}
#sum {
    background-color: black;
    font-size: 13px;
}
</style>