<template>
    <div>
        <div style="float: left"><h4>Live-Map</h4><h6>{{ databaseHeartbeat.length }} Vehicles</h6></div>
        <b-form-input v-model="filter" type="search" placeholder="Search" style="width: 200px; float: right; margin-bottom: 10px"></b-form-input><br /><br />
        <!-- <div style="float: right"><a href="live-map.html" target="_blank"><b><u>FULLSCREEN</u></b></a></div> -->
        <vue-friendly-iframe className="map" :src="livemap" @load="onLoad"></vue-friendly-iframe>
        <b-table responsive small striped sort-icon-left hover :items="databaseHeartbeat" :fields="fields" :filter="filter" id="table">
            <template #cell(config)="data">
                <b>{{ data.value }}</b>
            </template>
            <template #cell(status)="data">
                <b v-if="data.value == 'navigation'" style="color: green">{{ data.value }}</b>
                <span v-else>{{ data.value }}</span>
            </template>
            <template #cell(timestamp)="data">
                {{ timeSinceLastUpdate(data.value) }} ago
            </template>
        </b-table>
    </div>
</template>

<script>

export default {
    data() {
        return {
            livemap: "live-map.html",
            databaseHeartbeat: [],
            vehicles: [],
            filter: null,
            timer: '',
            fields: [
                // { key: 'deviceID', label: 'ID', sortable: true },
                // { key: 'deviceType', label: 'Device', sortable: true },
                // { key: 'stationID', label: 'StationID', sortable: true },
                { key: 'config', label: 'Vehicle ID', sortable: true },
                { key: 'plate', label: 'License Plate', sortable: true },
                { key: 'status', label: 'Status', sortable: true },
                { key: 'lat', label: 'Latitude', sortable: true },
                { key: 'lon', label: 'Longitude', sortable: true },
                { key: 'appVersion', label: 'App', sortable: true },
                { key: 'iOSVersion', label: 'iOS', sortable: true },
                { key: 'batterieState', label: 'Battery', sortable: true },
                { key: 'timestamp', label: 'Last Update', sortable: true },
            ]
        }
    },
    created() {
        this.refreshVehicles()
        this.refreshDevices()
        this.timer = setInterval(this.refreshDevices, 5000)
    },
    beforeDestroy() {
        this.cancelAutoUpdate()
    },
    methods: {
        onLoad() {
            //
        },
        refreshVehicles() {
            this.kmo_refreshVehicles().then(() => {
                this.vehicles = this.$store.vehicles
            })
        },
        refreshDevices() {
            this.kmo_refreshHeartbeat().then(() => {
                this.databaseHeartbeat = []
                this.$store.databaseHeartbeat.forEach(beat => {
                    if (beat.batterieState == "0") {
                        beat.batterieState = "Unknown"
                    }
                    if (beat.batterieState == "1") {
                        beat.batterieState = "Not charging"
                    }
                    if (beat.batterieState == "2") {
                        beat.batterieState = "Charging"
                    }
                    if (beat.batterieState == "3") {
                        beat.batterieState = "Full"
                    }
                    beat.batterieState += " - " + Math.ceil(beat.batterieLevel * 100) + "%"
                    if (beat.deviceType == "challenge") {
                        beat.deviceType = "iPad Station"
                    }
                    beat.appVersion = beat.appVersion.replace(' (', '.').replace(')', '')

                    var plate = ""
                    this.vehicles.forEach(vehicle => {
                        if (vehicle.vehicleID == beat.config) {
                            plate = vehicle.plate
                        }
                    })
                    beat.plate = plate
                    if (beat.config != "") {
                        this.databaseHeartbeat.push(beat)
                    }
                })
            })
        },
        timeSinceLastUpdate(timestamp) {
            let lastDate = new Date(timestamp)
            let now = new Date()
            var neededTime = ""

            var seconds = Math.floor((now - (lastDate))/1000)
            var minutes = Math.floor(seconds/60)
            var hours = Math.floor(minutes/60)
            var days = Math.floor(hours/24)

            // hours = (hours-(days*24)).toString().padStart(2, '0')
            minutes = (minutes-(days*24*60)-(hours*60)).toString().padStart(2, '0')
            seconds = (seconds-(days*24*60*60)-(hours*60*60)-(minutes*60)).toString().padStart(2, '0')

            if (days > 0) {
                if (days == 1) {
                    neededTime = "1 Day - " + ":" + minutes + ":" + seconds + " min"
                } else {
                    neededTime = days + " Days - " + ":" + minutes + ":" + seconds + " min"
                }
            } else {
                neededTime = minutes + ":" + seconds + " min"
            }

            return neededTime
        },
        cancelAutoUpdate () {
            clearInterval(this.timer)
        }
    },
}
</script>

<style>
#table {
    background-color: white;
}
.map {
    width: 100%;
    height: 600px;
    border-style: solid !important;
    border-color: black !important;
    border-width: 1px !important;
}
</style>