<template>
    <div>

        <!-- New Route Modal -->
        <b-modal ref="newRouteModal" body-bg-variant="light" hide-footer centered>
            <b-alert :show="alertShow" :variant="alertVariant">{{alertText}}</b-alert>
            <template #modal-header="{ }">
                <h4>Add new Route</h4>
            </template>
            <b-form @submit.stop.prevent>
                <b-container fluid style="padding: 0px">
                    <b-row align-v="center" style="padding: 4px;">
                        <b-col sm="3"> <label>Name:</label> </b-col>
                        <b-col sm="9"> <b-form-input v-model="newRouteName" placeholder="Name" autofocus v-on:keypress="isValidName($event)"></b-form-input> </b-col>
                    </b-row>
                    <b-row align-v="center" style="padding: 4px;">
                        <b-col sm="3"> <label>Event:</label> </b-col>
                        <b-col sm="9">
                            <b-form-select v-model="selectedEventID" :options="eventList"></b-form-select>
                        </b-col>
                    </b-row>
                </b-container>
                <br />
                <b-button class="button-secondary" style="float: left" @click="hideNewRouteModal()">Cancel</b-button>
                <b-button class="button-primary" style="float: right" @click="saveNewRoute()">Save</b-button>
            </b-form>
        </b-modal>

        <!-- Edit Waypoint Modal -->
        <b-modal ref="editWaypointModal" body-bg-variant="light" hide-footer centered>
            <b-alert :show="alertEditShow" :variant="alertEditVariant">{{alertEditText}}</b-alert>
            <template #modal-header="{ }">
                <h4 v-if="!isNewWaypoint">Edit Waypoint</h4>
                <h4 v-if="isNewWaypoint">New Waypoint</h4>
                <span v-if="(selectedWaypoint.type == 'hidden' || selectedWaypoint.type == 'swap') && !isNewWaypoint">
                    <b-button id="delete-confirm" size="sm" variant="danger" style="float: right;"><b-icon icon="
                        trash"></b-icon></b-button>
                    <b-popover variant="danger" target="delete-confirm" triggers="click" placement="bottom">
                        <template #title>Confirm</template>
                        <b-button variant="danger" @click="deleteNewWaypoint()">Delete Waypoint</b-button>
                    </b-popover>
                </span>
            </template>
            <b-form v-if="selectedWaypoint">
                <b-container fluid style="padding: 0px">
                    <b-row align-v="center" style="padding: 4px;">
                        <b-col sm="3"> <label>Waypoint:</label> </b-col>
                        <b-col sm="9"> <b-form-input v-model="selectedWaypoint.waypointName" placeholder="Waypoint" v-on:keypress="isValidName($event)"></b-form-input> </b-col>
                    </b-row>
                    <b-row align-v="center" style="padding: 4px;">
                        <b-col sm="3"> <label>Type:</label> </b-col>
                        <b-col sm="9">
                            <b-form-select v-if="selectedWaypoint.type == 'hidden' || selectedWaypoint.type == 'swap'" v-model="selectedWaypoint.type" :options="types"></b-form-select>
                            <b-form-input v-if="selectedWaypoint.type == 'start' || selectedWaypoint.type == 'end'" v-model="selectedWaypoint.type" placeholder="Type" disabled></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row align-v="center" style="padding: 4px;">
                        <b-col sm="3"> <label>Latitude:</label> </b-col>
                        <b-col sm="9"> <b-form-input v-model="selectedWaypoint.lat" placeholder="Latitude" v-on:keypress="isValidGeo($event)"></b-form-input> </b-col>
                    </b-row>
                    <b-row align-v="center" style="padding: 4px;">
                        <b-col sm="3"> <label>Longitude:</label> </b-col>
                        <b-col sm="9"> <b-form-input v-model="selectedWaypoint.lon" placeholder="Longitude" v-on:keypress="isValidGeo($event)"></b-form-input> </b-col>
                    </b-row>
                </b-container>
                <br />
                <b-button class="button-secondary" style="float: left" @click="hideEditWaypointModal()">Cancel</b-button>
                <b-button v-if="!isNewWaypoint" class="button-primary" style="float: right" @click="updateWaypoint()">Save</b-button>
                <b-button v-if="isNewWaypoint" class="button-primary" style="float: right" @click="saveNewWaypoint()">Save</b-button>
            </b-form>
        </b-modal>

         <!-- Geofencing Modal -->
         <b-modal ref="geofencingModal" body-bg-variant="light" hide-footer centered>
            <b-alert :show="alertShow" :variant="alertVariant">{{alertText}}</b-alert>
            <template #modal-header="{ }">
                <h4>Geofencing</h4>
            </template>
            <b-form-group>
                <b-form-checkbox-group
                    size="lg"
                    v-model="selectedPOIs"
                    :options="allPOIs"
                    switches
                    stacked
                ></b-form-checkbox-group>
            </b-form-group>
            <br />
            <b-button class="button-secondary" style="float: left" @click="hideGeofencingModal()">Cancel</b-button>
            <b-button class="button-primary" style="float: right" @click="saveGeofencing()">Save</b-button>
        </b-modal>

        <!-- Vehicles Modal -->
        <b-modal ref="vehiclesModal" body-bg-variant="light" hide-footer centered>
            <b-alert :show="alertShowVehicle" :variant="alertVariant">{{alertText}}</b-alert>
            <template #modal-header="{ }">
                <h4>Activated Vehicles on Route</h4>
            </template>
            <b-form-group>
                <b-form-checkbox-group
                    size="lg"
                    v-model="selectedVehicles"
                    :options="allVehicles"
                    switches
                    stacked
                ></b-form-checkbox-group>
                </b-form-group>
            <br />
            <b-button class="button-secondary" style="float: left" @click="hideVehiclesModal()">Cancel</b-button>
            <b-button class="button-primary" style="float: right" @click="saveVehicles()">Save</b-button>
        </b-modal>

        <h4>Routes</h4>
        <div>
            <b-form-select v-model="selectedEventID" :options="eventList" style="width: 280px; margin-right: 10px;" @change="loadRoutes()"></b-form-select>
            <b-form-select v-if="selectedEventID" v-model="selectedRouteID" :options="routeNames" style="width: 280px; margin-right: 10px;" @change="loadRoute()"></b-form-select>
            <b-button class="button-primary" @click="showNewRouteModal()">+ Route</b-button>
            <br /><br />
            <div>
                <b-table class="waypointTable" v-if="selectedRoute" :items="selectedRoute['waypoints']" :fields="fields" responsive bordered outlined>

                    <template #cell(icon)="row">
                        <b-icon :icon="iconForWaypoint(row.item)" class="h5 mb-2"></b-icon>
                    </template>

                    <template #cell(type)="data">
                        <i>{{ data.value }}</i>
                    </template>

                    <template #cell(up)="row">
                    <b-container fluid style="padding: 0px; float: right">
                        <b-button v-if="waypointCanGoUp(row.item)" class="button-secondary" size="sm" @click="moveWaypoint(row.item.waypointID, 'up')"><b-icon icon="arrow-bar-up"></b-icon></b-button>
                    </b-container>
                    </template>

                    <template #cell(down)="row">
                    <b-container fluid style="padding: 0px; float: right">
                        <b-button v-if="waypointCanGoDown(row.item)" class="button-secondary" size="sm" @click="moveWaypoint(row.item.waypointID, 'down')"><b-icon icon="arrow-bar-down"></b-icon></b-button>
                    </b-container>
                    </template>

                    <template #cell(edit)="row">
                        <b-button class="button-primary" style="float: right" size="sm" @click="editWaypoint(row.item)"><b-icon icon="pencil-square"></b-icon></b-button>
                    </template>
                    
                </b-table>

                <b-button v-if="selectedRouteID" class="button-primary" @click="showGeofencingModal()"><b-icon icon="pin-map" style="margin-right: 8px"></b-icon>Geofencing</b-button>
                <b-button v-if="selectedRouteID" class="button-primary" @click="showVehicleModal()" style="margin-left: 8px"><b-icon icon="speedometer2" style="margin-right: 8px"></b-icon>Vehicles</b-button>
                <b-button v-if="selectedRouteID" class="button-primary" @click="newWaypoint()" style="float: right">+ Waypoint</b-button>

            </div>
            <br />
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            selectedRouteID: null,
            selectedRoute: null,
            selectedEventID: null,
            routeNames: [],
            routes: [],
            poiList: [],
            vehicleList: [],
            eventList: [],
            alertShow: false,
            alertShowVehicle: false,
            alertText: "",
            alertVariant: "",
            fields: [
                { key: 'icon', label: '', thStyle: { width: "40px" }},
                { key: 'waypointName', label: 'Waypoint'},
                { key: 'type', label: 'Type'},
                { key: 'lat', label: 'Latitude'},
                { key: 'lon', label: 'Longitude'},
                { key: 'up', label: '', thStyle: { width: "60px" }},
                { key: 'down', label: '', thStyle: { width: "60px" }},
                { key: 'edit', label: '', thStyle: { width: "60px" }},
            ],
            types: [
                { value: 'start', text: 'start', disabled: true},
                { value: 'swap', text: 'swap'},
                { value: 'hidden', text: 'hidden'},
                { value: 'end', text: 'end', disabled: true},
            ],
            newRouteName: "",
            isNewWaypoint: false,
            alertEditShow: false,
            alertEditText: "",
            alertEditVariant: "",
            selectedWaypoint: null,
            selectedPOIs: [],
            selectedVehicles: [],
            allPOIs: [],
            allVehicles: []
        }
    },
    methods: {
        refreshEvents() {
            this.kmo_refreshEvents().then(() => {
                this.eventList = []
                this.eventList.push({
                    value: null,
                    text: "-- Select Event"
                })
                this.$store.events.forEach(event => {
                    this.eventList.push({
                        value: event.eventID,
                        text: event.eventName
                    })
                })
            })
        },
        refreshRoutes(initial) {
            this.kmo_refreshRoutes().then(() => {
                this.routes = this.$store.routes
                this.routeNames = []
                this.routes.forEach(route => {
                    if (route.eventID == this.selectedEventID) {
                        if (route.isDefault == "1" && initial) {
                            this.selectedRouteID = route.routeID
                            route.routeName = route.routeName + " (Default)"
                        }
                        this.routeNames.push({
                            value: route.routeID,
                            text: route.routeName
                        })
                    }
                })
                this.routeNames.unshift({
                    value: null,
                    text: "-- Select Route (" + this.routeNames.length + ")"
                })
                this.loadRoute()
            })
            this.kmo_refreshGeofencing().then(() => {
                this.poiList = this.$store.poiList
                this.generatePOIOptions()
            })
            this.kmo_refreshVehicleGroups().then(() => {
                this.vehicleList = this.$store.vehicleGroups
                this.generateVehicleOptions()
            })
        },
        loadRoutes() {
            this.selectedRouteID = null
            this.refreshRoutes()
        },
        loadRoute() {
            this.selectedRoute = null
            this.routes.forEach(route => {
                if (route.routeID == this.selectedRouteID) {
                    this.selectedRoute = route
                }
            })
        },
        generatePOIOptions() {
            this.allPOIs = []
            this.poiList.forEach(poi => {
                this.allPOIs.push( { value: poi.poiID, text: poi.poiName} )
            })
        },
        generateVehicleOptions() {
            this.allVehicles = []
            this.vehicleList.forEach(vehicle => {
                if (vehicle.vehicleGroupID != "99") {
                    this.allVehicles.push( { value: vehicle.vehicleGroupID, text: vehicle.name} )
                }
            })
        },
        showNewRouteModal() {
            this.newRouteName = ""
            this.alertShow = false
            this.$refs['newRouteModal'].show()
        },
        hideNewRouteModal() {
            this.alertShow = false
            this.$refs['newRouteModal'].hide()
        },
        saveNewRoute() {
            if (this.newRouteName && this.selectedEventID) {
                this.kmo_saveRoute(this.newRouteName, this.selectedEventID).then((result) => {
                    if (result[0]['affectedRows'] == 1) {
                        this.alertText = "Saved"
                        this.alertVariant = "success"
                        this.alertShow = true
                        this.selectedRouteID = result[0]['insertId']
                        this.hideNewRouteModal()
                        this.refreshRoutes()
                    } else {
                        this.alertText = "Server Error"
                        this.alertVariant = "danger"
                        this.alertShow = true
                    }
                })
            } else {
                this.alertText = "Fields can´t be empty"
                this.alertVariant = "danger"
                this.alertShow = true
            }
        },
        newWaypoint() {
            this.isNewWaypoint = true
            this.selectedWaypoint = {
                waypointID: 0,
                waypointName: "",
                sortID: "",
                type: "hidden",
                lat: "",
                lon: ""
            }
            this.alertEditShow = false
            this.$refs['editWaypointModal'].show()
        },
        editWaypoint(waypoint) {
            this.isNewWaypoint = false
            this.selectedWaypoint = JSON.parse(JSON.stringify(waypoint)) // Copy object
            this.alertEditShow = false
            this.$refs['editWaypointModal'].show()
        },
        hideEditWaypointModal() {
            this.alertEditShow = false
            this.$refs['editWaypointModal'].hide()
        },
        saveNewWaypoint() {
            if (this.selectedWaypoint.waypointName && this.selectedWaypoint.type && this.selectedWaypoint.lat && this.selectedWaypoint.lon) {
                this.kmo_saveWaypoint(this.selectedRouteID, this.selectedWaypoint.waypointName, this.selectedWaypoint.type, this.selectedWaypoint.lat, this.selectedWaypoint.lon).then(() => {
                    this.selectedRouteID = this.selectedRoute.routeID
                    this.hideEditWaypointModal()
                    this.refreshRoutes()
                })
            } else {
                this.alertEditText = "Fields can´t be empty"
                this.alertEditVariant = "danger"
                this.alertEditShow = true
            }
        },
        updateWaypoint() {
            if (this.selectedWaypoint.waypointName && this.selectedWaypoint.type && this.selectedWaypoint.lat && this.selectedWaypoint.lon) {
                this.kmo_updateWaypoint(this.selectedWaypoint.waypointID, this.selectedWaypoint.waypointName, this.selectedWaypoint.type, this.selectedWaypoint.lat, this.selectedWaypoint.lon).then((result) => {
                if (result[0]['affectedRows'] == 1) {
                    this.selectedRouteID = this.selectedRoute.routeID
                    this.hideEditWaypointModal()
                    this.refreshRoutes()
                } else {
                    this.alertEditText = "Server Error"
                    this.alertEditVariant = "danger"
                    this.alertEditShow = true
                }
            })
            } else {
                this.alertEditText = "Fields can´t be empty"
                this.alertEditVariant = "danger"
                this.alertEditShow = true
            }
        },
        moveWaypoint(waypointID, direction) {
            this.kmo_moveWaypoint(waypointID, direction).then(() => {
                this.refreshRoutes()
            })
        },
        deleteNewWaypoint() {
            this.kmo_deleteWaypoint(this.selectedWaypoint.waypointID).then(() => {
                this.selectedRouteID = this.selectedRoute.routeID
                this.hideEditWaypointModal()
                this.refreshRoutes()
            })
        },
        waypointCanGoUp(waypoint) {
            if (waypoint.type == 'hidden' || waypoint.type == 'swap') {
                if (this.selectedRoute.waypoints[1].waypointID != waypoint.waypointID) {
                    return true
                }
            }
            return false
        },
        waypointCanGoDown(waypoint) {
            if ((waypoint.type == 'hidden' || waypoint.type == 'swap')) {
                if (this.selectedRoute.waypoints[this.selectedRoute.waypoints.length-2].waypointID != waypoint.waypointID) {
                    return true
                }
            }
            return false
        },
        waypointCanDelete(waypoint) {
            if ((waypoint.type == 'hidden' || waypoint.type == 'swap')) {
                return true
            }
            return false
        },
        iconForWaypoint (waypoint) {
            switch (waypoint.type) {
                case "start": return "geo-fill"
                case "swap": return "people-fill"
                case "hidden": return "arrow-down"
                case "end": return "flag-fill"
                default: return ""
            }
        },
        showGeofencingModal() {
            this.kmo_poiMappings(this.selectedRouteID).then(pois => {
                this.selectedPOIs = []
                pois.forEach(poi => {
                    this.selectedPOIs.push(poi.poiID)
                })
            })
            this.alertShow = false
            this.$refs['geofencingModal'].show()
        },
        hideGeofencingModal() {
            this.selectedPOIs = []
            this.alertShow = false
            this.$refs['geofencingModal'].hide()
        },
        saveGeofencing() {
            this.kmo_poiMappingSave(this.selectedRouteID, this.selectedPOIs).then(() => {
                this.hideGeofencingModal()
            }) 
        },
        showVehicleModal() {
            this.kmo_vehicleMappings(this.selectedRouteID).then(vehicles => {
                this.selectedVehicles = []
                vehicles.forEach(vehicle => {
                    this.selectedVehicles.push(vehicle.vehicleGroupID)
                })
            })
            this.alertShowVehicle = false
            this.$refs['vehiclesModal'].show()
        },
        hideVehiclesModal() {
            this.selectedVehicles = []
            this.alertShowVehicle = false
            this.$refs['vehiclesModal'].hide()
        },
        saveVehicles() {
            this.kmo_vehicleMappingSave(this.selectedRouteID, this.selectedVehicles).then(() => {
                this.hideVehiclesModal()
            }) 
        },
        isValidGeo(e) {
            // let char = String.fromCharCode(e.keyCode)
            // if(/^[-.0-9]*$/.test(char)) return true
            // else e.preventDefault()
            console.log(e)
            return true
        },
        isValidName(e) {
            let char = String.fromCharCode(e.keyCode)
            if(/^[A-Za-z0-9 -]+$/.test(char)) return true
            else e.preventDefault()
        }
    },
    created() {
        this.refreshEvents()
        this.refreshRoutes(true)
    }
}
</script>

<style scoped>
.button-primary {
    background-color: #AA6C43 !important;
    border-color: #AA6C43 !important;
}
.button-secondary {
    background-color: white !important;
    border-color: #AA6C43 !important;
    color: #AA6C43 !important;
}
.button-danger {
    background-color: red !important;
    border-color: red !important;
    /* color: red !important; */
}
.waypointTable {
    background-color: white;
}
</style>