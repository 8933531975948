<template>
    <div>
        <div style="float: left"><h4>Netzwerk</h4></div>
        <div style="float: right">Anzahl: <b>{{ databaseHeartbeat.length }}</b></div>
        <b-form-input
            v-model="filter"
            type="search"
            size="lg"
            placeholder="Suche"
        ></b-form-input><br >
        <b-table responsive small striped sort-icon-left hover :items="databaseHeartbeat" :fields="fields" :filter="filter" id="table">
            <template #cell(timestamp)="data">
                {{ timeSinceLastUpdate(data.value) }} ago
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            databaseHeartbeat: [],
            filter: null,
            timer: '',
            fields: [
                // { key: 'deviceID', label: 'ID', sortable: true },
                { key: 'deviceType', label: 'Gerät', sortable: true },
                // { key: 'stationID', label: 'StationID', sortable: true },
                { key: 'config', label: 'Slot', sortable: true },
                { key: 'status', label: 'Status', sortable: true },
                // { key: 'lat', label: 'Latitude', sortable: true },
                // { key: 'lon', label: 'Longitude', sortable: true },
                { key: 'appVersion', label: 'App Version', sortable: true },
                { key: 'iOSVersion', label: 'iOS Version', sortable: true },
                { key: 'batterieState', label: 'Battery', sortable: true },
                { key: 'timestamp', label: 'Last Update', sortable: true },
            ]
        }
    },
    created() {
        this.refreshDevices()
        this.timer = setInterval(this.refreshDevices, 5000)
    },
    beforeDestroy() {
        this.cancelAutoUpdate()
    },
    methods: {
        refreshDevices() {
            this.kmo_refreshHeartbeat().then(() => {
                this.databaseHeartbeat = this.$store.databaseHeartbeat
                this.databaseHeartbeat.forEach(beat => {
                    if (beat.batterieState == "0") {
                        beat.batterieState = "Unknown"
                    }
                    if (beat.batterieState == "1") {
                        beat.batterieState = "Not charging"
                    }
                    if (beat.batterieState == "2") {
                        beat.batterieState = "Charging"
                    }
                    if (beat.batterieState == "3") {
                        beat.batterieState = "Full"
                    }
                    beat.batterieState += " - " + Math.ceil(beat.batterieLevel * 100) + "%"
                    if (beat.deviceType == "challenge") {
                        beat.deviceType = "iPad Station"
                    }
                })
            })
        },
        timeSinceLastUpdate(timestamp) {
            let lastDate = new Date(timestamp)
            let now = new Date()
            var neededTime = ""

            var seconds = Math.floor((now - (lastDate))/1000)
            var minutes = Math.floor(seconds/60)
            var hours = Math.floor(minutes/60)
            var days = Math.floor(hours/24)

            hours = (hours-(days*24)).toString().padStart(2, '0')
            minutes = (minutes-(days*24*60)-(hours*60)).toString().padStart(2, '0')
            seconds = (seconds-(days*24*60*60)-(hours*60*60)-(minutes*60)).toString().padStart(2, '0')

            if (days > 0) {
                if (days == 1) {
                    neededTime = "1 Day - " + hours + ":" + minutes + ":" + seconds
                } else {
                    neededTime = days + " Days - " + hours + ":" + minutes + ":" + seconds
                }
            } else {
                neededTime = hours + ":" + minutes + ":" + seconds
            }

            return neededTime
        },
        cancelAutoUpdate () {
            clearInterval(this.timer)
        }
    }
}
</script>

<style>
#table {
    background-color: white;
}
</style>