<template>
    <div>

        <b-modal v-model="checkShow" ok-only hide-footer hide-header-close centered>
            <template #modal-title>
                <h4>Zugangsdaten erneut versenden</h4>
            </template>
            <div>
                <h6>Der QR-Code und Zugangscode werden erneut an <strong>{{ user.mail }}</strong> gesandt!</h6>
            </div>
            <b-button variant="secondary" @click="checkShow = false" style="margin-top: 10px;"><strong>Abbrechen</strong></b-button>
            <b-button class="button-primary" variant="primary" @click="resendMail" style="float: right; margin-top: 10px;"><strong>Bestätigen</strong></b-button>
        </b-modal>

        <div style="float: left"><h4>Teilnehmer</h4></div>
        <div style="float: right">Anzahl: <b>{{ databaseUsers.length }}</b></div>
        <b-form-input
            v-model="filter"
            type="search"
            size="lg"
            placeholder="Suche"
        ></b-form-input><br >
        <b-table responsive small striped sort-icon-left hover :items="databaseUsers" :fields="fields" :filter="filter" id="table" stacked="sm">
            <template v-slot:cell(sendmail)>
                <!-- <b-button class="button-primary" style="float: right;" @click="resendMailCheck(item)">Senden</b-button> -->
                <b-button variant="secondary" style="float: right;">Senden</b-button>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            databaseUsers: [],
            filter: null,
            timer: '',
            checkShow: false,
            user: {},
            fields: [
                { key: 'eventID', label: 'Zugangscode', sortable: true },
                { key: 'firstname', label: 'Vorname', sortable: true },
                { key: 'lastname', label: 'Nachname', sortable: true },
                { key: 'companyname', label: 'Unternehmen', sortable: true },
                { key: 'customerID', label: 'Kundennummer', sortable: true },
                { key: 'mail', label: 'E-Mail', sortable: true },
                { key: 'language', label: 'Sprache', sortable: true },
                { key: 'wave', label: 'Registriert', sortable: true },
                // { key: 'mailSend', label: 'Mailed', sortable: true },
                { key: 'timestamp', label: 'Zeitstempel', sortable: true },
                // { key: 'sendmail', label: 'Zugangsdaten' },
            ]
        }
    },
    created() {
        this.refreshUsers()
        this.timer = setInterval(this.refreshUsers, 5000)
    },
    beforeDestroy() {
        this.cancelAutoUpdate()
    },
    methods: {
        refreshUsers() {
            this.kmo_refreshUsers().then(() => {
                this.databaseUsers = this.$store.databaseUsers
                this.databaseUsers.forEach(user => {
                    user['name'] = user['lastname'] + ', ' + user['firstname']
                    if (user['companyname'].length >= 30) {
                        user['companyname'] = user['companyname'].substring(0, 30) + "..."
                    }
                    if (user['wave'] == "0") {
                        user['wave'] = "Vor Ort"
                    }
                    if (user['wave'] == "1") {
                        user['wave'] = "Online"
                    }
                    if (user['language'] == "de_DE") {
                        user['language'] = "Deutsch"
                    }
                    if (user['language'] == "en_EN") {
                        user['language'] = "Englisch"
                    }});
            })
        },
        resendMailCheck(user) {
            this.user = user
            this.checkShow = true
        },
        resendMail() {
            this.kmo_resendMail(this.user.userID).then(()=> {
                this.checkShow = false
            });
        },
        cancelAutoUpdate () {
            clearInterval(this.timer)
        }
    }
}
</script>

<style>
#table {
    background-color: white;
}
.button-primary {
    background-color: #ff8500 !important;
    border-color: #ff8500 !important;
}
</style>