<template>
    <div>

        <!-- New / Edit Waypoint Modal -->
        <b-modal ref="editPOIModal" body-bg-variant="light" hide-footer centered>
            <b-alert :show="alertShow" :variant="alertVariant">{{alertText}}</b-alert>
            <template #modal-header="{ }">
                <h4 v-if="isNewPOI">New POI</h4>
                <h4 v-if="!isNewPOI">Edit POI</h4>
            </template>

            <b-form v-if="selectedPOI">
                <b-container fluid style="padding: 0px">
                    <b-row align-v="center" style="padding: 4px;">
                        <b-col sm="4"> <label>Name:</label> </b-col>
                        <b-col sm="8"> <b-form-input v-model="selectedPOI.poiName" placeholder="Name" v-on:keypress="isValidName($event)"></b-form-input> </b-col>
                    </b-row>
                    <b-row align-v="center" style="padding: 4px;">
                        <b-col sm="4"> <label>Trigger:</label> </b-col>
                        <b-col sm="8">
                            <b-form-select v-model="selectedPOI.triggerType" :options="triggerTypes"></b-form-select>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row align-v="center" style="padding: 4px;">
                        <b-col sm="4"> <label>Terramar:</label> </b-col>
                        <b-col sm="8">
                            <b-form-select v-model="selectedPOI.vehicleGroupPoiID1" :options="contentKeysVG1"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row align-v="center" style="padding: 4px;">
                        <b-col sm="4"> <label>Tavascan:</label> </b-col>
                        <b-col sm="8">
                            <b-form-select v-model="selectedPOI.vehicleGroupPoiID2" :options="contentKeysVG2"></b-form-select>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row align-v="center" style="padding: 4px;">
                        <b-col sm="4"> <label>Latitude:</label> </b-col>
                        <b-col sm="8"> <b-form-input v-model="selectedPOI.lat" placeholder="Latitude" v-on:keypress="isValidGeo($event)"></b-form-input> </b-col>
                    </b-row>
                    <b-row align-v="center" style="padding: 4px;">
                        <b-col sm="4"> <label>Longitude:</label> </b-col>
                        <b-col sm="8"> <b-form-input v-model="selectedPOI.lon" placeholder="Longitude" v-on:keypress="isValidGeo($event)"></b-form-input> </b-col>
                    </b-row>
                    <b-row align-v="center" style="padding: 4px;">
                        <b-col sm="4"> <label>Radius:</label> </b-col>
                        <b-col sm="8"> <b-form-input v-model="selectedPOI.radius" placeholder="Radius" v-on:keypress="isValidNumber($event)"></b-form-input> </b-col>
                    </b-row>
                </b-container>
                <br />
                <b-button class="button-secondary" style="float: left" @click="hideEditPOIModal()">Cancel</b-button>
                <b-button v-if="!isNewPOI" class="button-primary" style="float: right" @click="updatePOI()">Save</b-button>
                <b-button v-if="isNewPOI" class="button-primary" style="float: right" @click="savePOI()">Save</b-button>

            </b-form>
        </b-modal>

        <div style="float: left"><h4>Geofencing</h4></div>
        <div style="float: right">Anzahl: <b>{{ poiList.length }}</b></div>
        <b-form-input
            v-model="filter"
            type="search"
            size="lg"
            placeholder="Volltextsuche"
        ></b-form-input><br />
        <div>

            <b-button class="button-primary" @click="newPOI()" style="float: right">+ Point Of Interest</b-button><br /><br />

            <b-table class="podcastTable" :items="poiList" :fields="fields" :filter="filter" responsive bordered outlined>

                <template #cell(poiName)="data">
                    <b>{{ data.value }}</b>
                </template>

                <template #cell(contentKey)="data">
                    {{ speechNameForKey(data.value) }}
                </template>

                <template #cell(edit)="row">
                    <b-button class="button-primary" style="float: right" size="sm" @click="editPOI(row.item)" :disabled="disabled"><b-icon icon="pencil-square"></b-icon></b-button>
                </template>
                
            </b-table>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            poiList: [],
            speechList: [],
            filter: null,
            selectedPOI: null,
            alertShow: false,
            alertText: "",
            alertVariant: "",
            isNewPOI: false,
            disabled: false,
            fields: [
                { key: 'poiName', label: 'Name'},
                { key: 'triggerType', label: 'Trigger'},
                { key: 'vehicleGroupPoiName1', label: 'Terramar'},
                { key: 'vehicleGroupPoiName2', label: 'Tavascan'},
                { key: 'lat', label: 'Latitude'},
                { key: 'lon', label: 'Longitude'},
                { key: 'radius', label: 'Radius'},
                { key: 'edit', label: 'Edit', thStyle: { width: "60px" }},
            ],
            triggerTypes: [
                { value: 'podcast', text: 'Podcast'}
            ],
            contentKeysVG1: [],
            contentKeysVG2: [],
            contentKeysAll: []
        }
    },
    methods: {
        refreshGeofencing() {
            this.kmo_refreshSpeechList().then(() => {
                this.speechList = this.$store.speechList
                this.generateContentKeys()
                this.kmo_refreshGeofencing().then(() => {
                    this.poiList = this.$store.poiList
                    this.poiList.forEach(poi => {
                        this.contentKeysAll.forEach(contentKey => {
                            if (poi.vehicleGroupPoiID1 == contentKey.value) { poi.vehicleGroupPoiName1 = contentKey.text }
                            if (poi.vehicleGroupPoiID2 == contentKey.value) { poi.vehicleGroupPoiName2 = contentKey.text }
                        })
                    })
                })
            })
        },
        generateContentKeys() {
            this.contentKeysVG1 = []
            this.contentKeysVG2 = []
            this.contentKeysAll = []      
            this.speechList.forEach(speech => {
                switch (speech.vehicleGroupID) {
                    case "1": this.contentKeysVG1.push( { value: speech.speechKey, text: speech.speechName } ); break;
                    case "2": this.contentKeysVG2.push( { value: speech.speechKey, text: speech.speechName } ); break;
                    case "99":
                        this.contentKeysVG1.push( { value: speech.speechKey, text: speech.speechName } )
                        this.contentKeysVG2.push( { value: speech.speechKey, text: speech.speechName } )
                        break;
                }
                this.contentKeysAll.push( { value: speech.speechKey, text: speech.speechName } )
            })
        },
        speechNameForKey(speechKey) {
            var foundSpeechName = ""
            this.speechList.every(speech => {
                if (speech.speechKey == speechKey) {
                    foundSpeechName = speech.speechName
                    return false
                }
                return true
            })
            return foundSpeechName
        },
        newPOI() {
            this.selectedPOI = {
                poiName: "",
                triggerType: "podcast",
                vehicleGroupID1: "",
                vehicleGroupID2: "",
                lat: "",
                lon: "",
                radius: ""
            }
            this.isNewPOI = true
            this.alertShow = false
            this.$refs['editPOIModal'].show()
        },
        editPOI(poi) {
            this.selectedPOI = JSON.parse(JSON.stringify(poi)) // Copy object
            this.isNewPOI = false
            this.alertShow = false
            this.$refs['editPOIModal'].show()
        },
        hideEditPOIModal() {
            this.alertShow = false
            this.$refs['editPOIModal'].hide()
        },
        savePOI() {
            if (this.selectedPOI.poiName && this.selectedPOI.triggerType && this.selectedPOI.lat && this.selectedPOI.lon && this.selectedPOI.radius) {
                let contentKeys = this.selectedPOI.vehicleGroupPoiID1 + "," + this.selectedPOI.vehicleGroupPoiID2
                this.kmo_savePOI(this.selectedPOI.poiName, this.selectedPOI.triggerType, contentKeys, this.selectedPOI.lat, this.selectedPOI.lon, this.selectedPOI.radius).then(() => {
                    this.hideEditPOIModal()
                    this.refreshGeofencing()
                })
            } else {
                this.alertText = "Fields can´t be empty"
                this.alertVariant = "danger"
                this.alertShow = true
            }
        },
        updatePOI() {
            if (this.selectedPOI.poiName && this.selectedPOI.triggerType && this.selectedPOI.lat && this.selectedPOI.lon && this.selectedPOI.radius) {
                let contentKeys = this.selectedPOI.vehicleGroupPoiID1 + "," + this.selectedPOI.vehicleGroupPoiID2
                this.kmo_updatePOI(this.selectedPOI.poiID, this.selectedPOI.poiName, this.selectedPOI.triggerType, contentKeys, this.selectedPOI.lat, this.selectedPOI.lon, this.selectedPOI.radius).then(() => {
                    this.$refs['editPOIModal'].hide()
                    this.refreshGeofencing()
                })
            } else {
                this.alertText = "Fields can´t be empty"
                this.alertVariant = "danger"
                this.alertShow = true
            }
        },
        showDelete(speech, languageKey) {
            this.selectedSpeech = speech
            this.uploadLanguageKey = languageKey
            switch (languageKey) {
                case "de_DE": this.uploadLanguageName = "German"; break;
                case "en_EN": this.uploadLanguageName = "English"; break;
            }
            this.$refs['deleteModal'].show()
        },
        hideDelete() {
            this.$refs['deleteModal'].hide()
        },
        isValidGeo(e) {
            // let char = String.fromCharCode(e.keyCode)
            // if(/^[-.0-9]*$/.test(char)) return true
            // else e.preventDefault()
            console.log(e)
            return true
        },
        isValidNumber(e) {
            let char = String.fromCharCode(e.keyCode)
            if(/^[0-9]+$/.test(char)) return true
            else e.preventDefault()
        },
        isValidName(e) {
            let char = String.fromCharCode(e.keyCode)
            if(/^[A-Za-z0-9- ]+$/.test(char)) return true
            else e.preventDefault()
        }
    },
    created() {
        this.refreshGeofencing()
    },
}
</script>

<style scoped>
.button-primary {
    background-color: #AA6C43 !important;
    border-color: #AA6C43 !important;
}
.button-secondary {
    background-color: white !important;
    border-color: #AA6C43 !important;
    color: #AA6C43 !important;
}
.button-danger {
    background-color: red !important;
    border-color: red !important;
    /* color: red !important; */
}
.podcastTable {
    background-color: white;
}
</style>