<template>
    <div>
        <div><h4>Feedback</h4></div>
        <Pie
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
        />
    </div>
</template>

<script>
import { Pie } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
    name: 'PieChart',
    components: {
        Pie
    },
    props: {
        chartId: {
            type: String,
            default: 'pie-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {}
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            yes: 0,
            no: 0,
            timer: '',
            chartData: {},
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false
            }
        }
    },
    created() {
        this.refreshFeedback()
        this.timer = setInterval(this.refreshFeedback, 5000)
    },
    beforeDestroy() {
        this.cancelAutoUpdate()
    },
    methods: {
        refreshFeedback() {
            this.kmo_refreshFeedback().then(() => {
                this.yes = this.$store.feedbacks[0]
                this.no = this.$store.feedbacks[1]
                let percentYes = Math.round((this.yes * 100) / (this.yes + this.no))
                let percentNo = 100 - percentYes
                this.chartData = {
                    labels: ["YES " + percentYes + "% (" + this.yes + ")", "NO " + percentNo + "% (" + this.no + ")"],
                    datasets: [
                        {
                            backgroundColor: ['#AA6C43', '#999999'],
                            data: [this.yes, this.no]
                        }
                    ]
                }
            })
        },
        cancelAutoUpdate () {
            clearInterval(this.timer)
        }
    }
}
</script>

<style>
#chart {
    background-color: white;
}
#color {
    background-color: #AA6C43;
    font-size: 13px;
}
.textTable {
    background-color: white;
}
</style>