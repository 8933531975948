<template>
    <div>
        <div><h4>Beantwortete Fragen</h4></div>
        <b-table striped hover :items="answers" :fields="fields" id="table">
            <template #cell(correct)="data">
                <span v-html="data.value"></span>
            </template>
            <template #cell(incorrect)="data">
                <span v-html="data.value"></span>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            answers: [],
            fields: [
                { key: 'station', label: 'Station' },
                { key: 'correct', label: 'Richtig' },
                { key: 'incorrect', label: 'Falsch' },
            ]
        }
    },
    created() {
        this.refreshAnswers()
    },
    methods: {
        refreshAnswers() {
            this.kmo_refreshAnswers().then(() => {
                this.answers = this.$store.answers
                this.answers.forEach(answer => {
                    switch (answer['station']) {
                        case "ts01": answer['station'] = "Station 1"; break;
                        case "ts02": answer['station'] = "Station 2"; break;
                        case "ts03": answer['station'] = "Station 3"; break;
                        case "ts04": answer['station'] = "Station 4"; break;
                        case "ts05": answer['station'] = "Station 5"; break;
                        default: break;
                    }
                    const percentCorrect = Math.ceil((answer['correct'] * 100) / (answer['correct'] + answer['incorrect']))
                    const percentIncorrect = 100 - percentCorrect
                    answer['correct'] = "<b style='color: #AA6C43;'>" + percentCorrect + "%</b> (" + answer['correct'] + ")"
                    answer['incorrect'] = "<b style='color: #cc0033;'>" + percentIncorrect + "%</b> (" + answer['incorrect'] + ")"
                })
            })
        }
    }
}
</script>

<style>

</style>