<template>
    <div style="margin: 20px">
        <h2>Teilnehmer Import</h2>
        <hr>
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            Daten nicht komplett. Bitte Tag und XLSX angeben!
        </b-alert>
        <b-alert v-model="showSuccessAlert" variant="success" dismissible>
            Es wurden {{ backendResponse.userAddedCount }} von {{ backendResponse.userCount }} Teilnehmern importiert!
        </b-alert>
        <b-form inline>
            <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
            <b-form-select
                id="input-1"
                size="lg"
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="waveNumber"
                :options=waveOptions
                required
            ></b-form-select>
            </b-input-group>
            <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
            <b-form-file class="mb-2 mr-sm-2 mb-sm-0"
                size="lg"
                v-model="file"
                placeholder=""
                drop-placeholder=""
                accept=".xlsx"
            ></b-form-file>
            </b-input-group>
            <b-button variant="primary" size="lg" @click="importUsers()">Import</b-button>
        </b-form>
        <hr>
    </div>
</template>

<script>
export default {
    data() {
        return {
            events: [],
            showDismissibleAlert: false,
            showSuccessAlert: false,
            waveNumber: null,
            waveOptions: [],
            file: null,
            backendResponse: {
                userCount: 0,
                userAddedCount: 0,
                dublicateUserIDs: []
            }
        }
    },
    methods: {
        refreshEvents() {
            this.waveOptions = []
            this.kmo_refreshEvents().then(() => {
                this.events = this.$store.events
                this.events.forEach(event => {
                    if (event.activeEvent) {
                        this.waveOptions.push({value: null, text: "Tag wählen "})
                        event.days.forEach((day, index) => {
                            this.waveOptions.push({value: index+1, text: event.eventName + " - " + day})
                        })
                    }
                });
            })
        },
        importUsers() {
            if (this.waveNumber && this.file) {
                this.kmo_importUsers(this.file, this.waveNumber).then((response) => {
                    this.backendResponse.userCount = response['users_count']
                    this.backendResponse.userAddedCount = response['users_added_count']
                    this.backendResponse.dublicateUserIDs = response['dublicate_user_ids']
                    this.showSuccessAlert = true
                });
            } else {
                this.showDismissibleAlert = true
            }
        }
    },
    created() {
        this.refreshEvents()
    }
}
</script>

<style>

</style>