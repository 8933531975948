<template>
    <div>
        <h2>Survey PDFs</h2>

    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        refreshRoutes() {
            
        }
    },
    created() {
        
    }
}
</script>

<style>

</style>