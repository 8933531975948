<template>
    <div>
        <div style="float: left"><h4>Safety-Consents</h4></div>
        <div style="float: right">Amount: <b>{{ filteredConsents.length }}</b></div><br />
        <b-form-select v-model="selectedDate" :options="possibleDates" style="width: 190px; float: right; margin-bottom: 10px; margin-top: 10px;" @change="filterDate()"></b-form-select>
        <b-form-input @input="filterConsents()" v-model="searchText" type="search" size="lg" placeholder="Search" style="margin-bottom: 10px;"></b-form-input>
        <b-table responsive small striped sort-icon-left hover :items="filteredConsents" :fields="fields" :filter="filter" id="table">
        </b-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            consents: [],
            filteredConsents: [],
            filter: null,
            searchText: null,
            selectedDate: 'all',
            possibleDates: [
                { value: 'all', text: 'All Days'},
            ],
            fields: [
                { key: 'mappedID', label: 'Vehicle ID', sortable: true },
                { key: 'timestamp', label: 'Consents Timestamp', sortable: true },
            ]
        }
    },
    created() {
        this.refreshVehicles()
    },
    methods: {
        refreshVehicles() {
            var dateOptions = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            }
            var filterOptions = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }
            this.kmo_refreshConsents().then(() => {
                this.consents = this.$store.consents

                let allDates = []
                this.consents.forEach(consent => {
                    let newDate = new Date(consent['timestamp'])
                    let dateString = newDate.toLocaleDateString("de", dateOptions)
                    consent['timestamp'] = dateString.replaceAll(",", " - ")

                    let filterString = newDate.toLocaleDateString("de", filterOptions)
                    allDates.push(filterString)
                })

                this.possibleDates = [ { value: 'all', text: 'All Days'} ]
                let filterStringSet = new Set(allDates)
                filterStringSet.forEach(date => {
                    this.possibleDates.push( { value: date, text: date } )
                })

                this.filterDate()
            })
        },
        filterDate() {
            if (this.selectedDate == "all") {
                this.filteredConsents = this.consents
            } else {
                this.filteredConsents = []
                this.consents.forEach(consent => {
                    if (consent['timestamp'].includes(this.selectedDate)) {
                        this.filteredConsents.push(consent)
                    }
                })
            }
        },
        filterConsents() {
            this.filter = this.searchText
        },
    }
}
</script>

<style>
#table {
    background-color: white;
}
.button-primary {
    background-color: #ff8500 !important;
    border-color: #ff8500 !important;
}
</style>