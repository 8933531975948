<template>
    <div>
        <div style="float: left"><h4>Passed Station</h4></div>
        <div style="float: right">Passed: <b>{{ passedCount }}</b></div>
        <b-form-input
            v-model="filter"
            type="search"
            size="lg"
            placeholder="Search for nfcID, name, etc."
        ></b-form-input><br />
        <b-table responsive small striped sort-icon-left hover :items="passed" :fields="fields" :filter="filter">
            <template v-slot:cell(product)="data"> <b-form-checkbox v-model="data.item.product" @input="checkboxChanged(data.item, 'product', data.item.product)"></b-form-checkbox> </template>
            <template v-slot:cell(compare)="data"> <b-form-checkbox v-model="data.item.compare" @input="checkboxChanged(data.item, 'compare', data.item.compare)"></b-form-checkbox> </template>
            <template v-slot:cell(connectivity)="data"> <b-form-checkbox v-model="data.item.connectivity" @input="checkboxChanged(data.item, 'connectivity', data.item.connectivity)"></b-form-checkbox> </template>
            <template v-slot:cell(assistance)="data"> <b-form-checkbox v-model="data.item.assistance" @input="checkboxChanged(data.item, 'assistance', data.item.assistance)"></b-form-checkbox> </template>
            <template v-slot:cell(electric)="data"> <b-form-checkbox v-model="data.item.electric" @input="checkboxChanged(data.item, 'electric', data.item.electric)"></b-form-checkbox> </template>
            <template v-slot:cell(accessories)="data"> <b-form-checkbox v-model="data.item.accessories" @input="checkboxChanged(data.item, 'accessories', data.item.accessories)"></b-form-checkbox> </template>
            <template v-slot:cell(sportstourer)="data"> <b-form-checkbox v-model="data.item.sportstourer" @input="checkboxChanged(data.item, 'sportstourer', data.item.sportstourer)"></b-form-checkbox> </template>
        </b-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            passed: [],
            intervall: "",
            updateIsActive: true,
            passedCount: 0,
            filter: null,
            fields: [
                { key: 'nfcID', label: 'NFC', sortable: true },
                { key: 'name', label: 'Name', sortable: true },
                { key: 'product', label: 'Product' },
                { key: 'compare', label: 'Competitiors' },
                { key: 'connectivity', label: 'Connectivity' },
                { key: 'assistance', label: 'Assistance' },
                { key: 'electric', label: 'Electric' },
                { key: 'accessories', label: 'Accessories' },
                { key: 'sportstourer', label: 'Sportstourer' },
            ]
        }
    },
    methods: {
        startTimer() {
            this.intervall = setInterval(function() {
                if (this.updateIsActive) {
                    this.refreshUsersPassed()
                }
            }.bind(this), 5000);
        },
        refreshUsersPassed() {
            this.kmo_refreshUsersPassed().then(() => {
                this.passed = this.$store.passed

                this.passedCount = 0

                this.passed.forEach(pass => {
                    pass['name'] = pass['lastname'] + ', ' + pass['firstname']
                    pass['nfcID'] = pass['nfcID'].substring(2)
                    pass['stations'].forEach(station => {
                        switch (station) {
                            case 'workshop-product': pass['product'] = true; break;
                            case 'workshop-compare': pass['compare'] = true; break;
                            case 'connectivity': pass['connectivity'] = true; break;
                            case 'leon-ar': pass['assistance'] = true; break;
                            case 'table-customer': pass['electric'] = true; break;
                            case 'accessories': pass['accessories'] = true; break;
                            case 'sportstourer': pass['sportstourer'] = true; break;
                            default: break;
                        }
                    });
                    this.passedCount += pass['stations'].length
                });
            })
        },
        checkboxChanged(user, station, pass) {

            var stationName = ""
            switch (station) {
                case "product": stationName = "workshop-product"; break;
                case "compare": stationName = "workshop-compare"; break;
                case "connectivity": stationName = "connectivity"; break;
                case "assistance": stationName = "leon-ar"; break;
                case "electric": stationName = "table-customer"; break;
                case "accessories": stationName = "accessories"; break;
                case "sportstourer": stationName = "sportstourer"; break;
                default: break;
            }

            var params = {
                "userID": user.userID,
                "stationGroup": stationName,
                "passed": pass
            }

            this.kmo_savePassed(params).then((response) => {
                console.log(response)
            });
        }
    },
    created() {
        this.updateIsActive = true
        this.refreshUsersPassed()
        this.startTimer()
    },
    beforeDestroy() {
        this.updateIsActive = false
    }
}
</script>

<style>

</style>