<template>
    <div>
        <div><h4>Spielzeit</h4></div>
        <Bar
            id="chart"
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
        /><br />
        <b-table striped hover :items="tableItems" id="table" responsive>
            <template #cell(Challenge)="data">
                <span v-html="data.value"></span>
            </template>
            <template #cell(Gesamtdauer)="data">
                <span v-html="data.value"></span>
            </template>
        </b-table>
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'BarChart',
    components: {
        Bar
    },
    props: {
        chartId: {
            type: String,
            default: 'bar-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {}
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            slotTimes: [],
            loaded: false,
            times: [0, 0, 0, 0, 0],
            counts: [0, 0, 0, 0, 0],
            lengths: ["1", "", "", "", ""],
            tableItems: [],
            chartData: {},
            chartOptions: {
                responsive: true, 
                maintainAspectRatio: false,
                scales: {
                    y: {
                        title: {
                            color: 'black',
                            display: true,
                            text: 'Stunden'
                        }
                    }
                }
            }
        }
    },
    created() {
        this.refreshPassed()
    },
    methods: {
        refreshPassed() {
            this.loaded = false
            this.kmo_refreshSlotTimes().then(() => {
                this.slotTimes = this.$store.slotTimes
                this.slotTimes.forEach(slot => {
                    switch (slot['slot']) {
                        case "ts01":
                            this.times[0] = slot['time'];
                            this.counts[0] = slot['count'];
                            break;
                        case "ts02":
                            this.times[1] = slot['time'];
                            this.counts[1] = slot['count'];
                            break;
                        case "ts03":
                            this.times[2] = slot['time'];
                            this.counts[2] = slot['count'];
                            break;
                        case "ts04":
                            this.times[3] = slot['time'];
                            this.counts[3] = slot['count'];
                            break;
                        case "ts05":
                            this.times[4] = slot['time'];
                            this.counts[4] = slot['count'];
                            break;
                        default: break;
                    }
                })
                this.tableItems.push(
                    {
                        Challenge: "Station 1",
                        Gesamtdauer: this.timeToString(this.times[0]/10) + " Std.",
                        Spieldauer_Ø: "Ø " + this.timeToString((this.times[0]/10) / this.counts[0]) + " Std."
                    },
                    {
                        Challenge: "Station 2",
                        Gesamtdauer: this.timeToString(this.times[1]/10) + " Std.",
                        Spieldauer_Ø: "Ø " + this.timeToString((this.times[1]/10) / this.counts[1]) + " Std."
                    },
                    {
                        Challenge: "Station 3",
                        Gesamtdauer: this.timeToString(this.times[2]/10) + " Std.",
                        Spieldauer_Ø: "Ø " + this.timeToString((this.times[2]/10) / this.counts[2]) + " Std."
                    },
                    {
                        Challenge: "Station 4",
                        Gesamtdauer: this.timeToString(this.times[3]/10) + " Std.",
                        Spieldauer_Ø: "Ø " + this.timeToString((this.times[3]/10) / this.counts[3]) + " Std."
                    },
                    {
                        Challenge: "Station 5",
                        Gesamtdauer: this.timeToString(this.times[4]/10) + " Std.",
                        Spieldauer_Ø: "Ø " + this.timeToString((this.times[4]/10) / this.counts[4]) + " Std."
                    },
                    {
                        Challenge: "<b>Gesamt</b>",
                        Gesamtdauer: "<b>" + this.timeToString((this.times[0]+this.times[1]+this.times[2]+this.times[3]+this.times[4])/10) + " Std.</b>"
                    }
                )
                this.chartData = {
                    labels: [
                        'Challenge 1',
                        'Challenge 2',
                        'Challenge 3',
                        'Challenge 4',
                        'Challenge 5'
                    ],
                    datasets: [
                        {
                            label: 'Stunden',
                            backgroundColor: '#AA6C43',
                            data: [
                                this.times[0]/10/60/60,
                                this.times[1]/10/60/60,
                                this.times[2]/10/60/60,
                                this.times[3]/10/60/60,
                                this.times[4]/10/60/60
                            ]
                        }
                    ]
                }
                this.loaded = true
            })
        },
        timeToString(seconds) {
            return new Date(seconds * 1000).toISOString().slice(11, 19);
        }
    }
}
</script>

<style>
#chart {
    background-color: white;
}
#color {
    background-color: #AA6C43;
    font-size: 13px;
}
#sum {
    background-color: black;
    font-size: 13px;
}
</style>