<template>
    <div>
        <div style="float: left"><h4>Teilnehmer an Station</h4></div>
        <div style="float: right">Anzahl: <b>{{ users.length }}</b></div>
        <b-form-input
            v-model="filter"
            type="search"
            size="lg"
            placeholder="Suche"
        ></b-form-input><br >
        <b-table responsive small striped sort-icon-left hover :items="users" :fields="fields" :filter="filter" id="table">
            
        </b-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            users: [],
            filter: null,
            timer: '',
            fields: [
                { key: 'userID', label: 'Zugangscode', sortable: true },
                { key: 'stationGroup', label: 'Station', sortable: true },
                { key: 'stationID', label: 'StationID', sortable: true },
                { key: 'slotIndex', label: 'Slot', sortable: true },
                { key: 'status', label: 'Status', sortable: true },
                { key: 'timestamp', label: 'Zeitstempel', sortable: true },
            ]
        }
    },
    created() {
        this.refreshLiveUsers()
        this.timer = setInterval(this.refreshLiveUsers, 5000)
    },
    beforeDestroy() {
        this.cancelAutoUpdate()
    },
    methods: {
        refreshLiveUsers() {
            this.kmo_refreshLiveUsers().then(() => {
                this.users = this.$store.users
            })
        },
        cancelAutoUpdate () {
            clearInterval(this.timer)
        }
    }
}
</script>

<style>
#table {
    background-color: white;
}
</style>