<template>
    <div style="margin: 0px">
        <b-container fluid>
            <b-row align-h="start">
                <b-col cols="12">
                    <h2>Settings</h2>
                    <hr>
                </b-col>
            </b-row>
            <b>KAMINO-Backend Server</b><br />{{ this.$http.options.root }}
            <!-- <b-row align-h="start">
                <b-col cols="12" md="6" sm="8">
                    <h5>Rain-Mode</h5>
                    <b-form-select v-model="rainMode" :options="options" size="lg"></b-form-select>
                </b-col>
            </b-row>
            <b-row align-h="start">
                <b-col cols="12">
                    <hr>
                    <b-button size="lg" @click="changeSettings()" variant="primary">Save</b-button>
                </b-col>
            </b-row> -->
        </b-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // rainMode: '0',
            // parkingCode: '0',
            // rainModeInit: '0',
            // parkingCodeInit: '0',
            // options: [
            //     { value: '0', text: 'Disabled' },
            //     { value: '1', text: 'Enabled' }
            // ]
        }
    },
    methods: {
        // refreshSettings() {
        //     this.kmo_refreshSettings().then(() => {
        //         this.rainMode = this.$store.rainMode
        //         this.rainModeInit = this.$store.rainMode
        //     })
        // },
        // changeSettings() {
        //     this.changeRainMode()
        // },
        // changeRainMode() {
        //     if (this.rainMode != this.rainModeInit) {
        //         if (this.rainMode == true) {
        //             this.$bvModal.msgBoxConfirm("Enabling Rain-Mode will remove the Scooter- and eBike-Experience from the smart MEX App.", {
        //                 title: 'Please Confirm',
        //                 size: 'sm',
        //                 buttonSize: 'lg',
        //                 okVariant: 'danger',
        //                 okTitle: 'ENABLE',
        //                 cancelTitle: 'Cancel',
        //                 hideHeaderClose: true,
        //                 centered: true
        //                 })
        //             .then(value => {
        //                 if (value == true) {
        //                     this.kmo_setRainMode(this.rainMode).then((response) => {
        //                         console.log(response)
        //                         this.$bvToast.toast("Rain-Mode successfuly enabled!", {
        //                             title: "Info",
        //                             autoHideDelay: 5000,
        //                             append: true
        //                         })
        //                     });
        //                 } else {
        //                     this.refreshSettings();
        //                 }
        //             })
        //             .catch(err => {
        //                 console.log(err)
        //             });
        //         } else {
        //             this.kmo_setRainMode(this.rainMode).then((response) => {
        //                 console.log(response)
        //                 var message = "disabled"
        //                 if (this.rainMode == "1") {
        //                     message = "enabled"
        //                 }
        //                 this.$bvToast.toast("Rain-Mode successfuly " + message + "!", {
        //                     title: "Info",
        //                     autoHideDelay: 5000,
        //                     append: true
        //                 })
        //             });
        //         }
        //         this.rainModeInit = this.rainMode
        //     }
        // }
    },
    created() {
        // this.refreshSettings();
    }
}
</script>

<style>

</style>