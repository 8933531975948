<template>
    <div id="background">

        <b-modal v-model="errorShow" ok-only hide-footer hide-header-close centered>
          <template #modal-title>
            <h4>Fehler</h4>
          </template>
          <div>
            <h6>Es wurde kein Teilnehmer mit diesem Zugangscode gefunden.</h6>
          </div>
          <b-button class="button-primary" variant="primary" @click="errorShow = false" style="float: right; margin-top: 10px;"><strong>OK</strong></b-button>
        </b-modal>
        
        <b-modal v-model="infoShow" ok-only hide-footer hide-header-close centered>
          <template #modal-title>
            <h4>Münzen Zustände</h4>
          </template>
          <b-container>
            <b-row align-v="center">
              <b-col cols="4">
                <b-img id="coin" src="coin0.png"></b-img>
              </b-col>
              <b-col>
                <h6>Station wurde noch nicht absolviert</h6>
              </b-col>
            </b-row>
            <b-row align-v="center">
              <b-col cols="4">
                <b-img id="coin" src="coin1.png"></b-img>
              </b-col>
              <b-col>
                <h6>Keine Münze an Station erspielt</h6>
              </b-col>
            </b-row>
            <b-row align-v="center">
              <b-col cols="4">
                <b-img id="coin" src="coin2.png"></b-img>
              </b-col>
              <b-col>
                <h6>Münze an Station erspielt</h6> <span style="color: darkgray;"><i>(Anklicken um Auszahlung zu bestätigen)</i></span>
              </b-col>
            </b-row>
            <b-row align-v="center">
              <b-col cols="4">
                <b-img id="coin" src="coin3.png"></b-img>
              </b-col>
              <b-col>
                <h6>Münze wurde ausgezahlt</h6> <span style="color: darkgray;"><i>(Anklicken um Auszahlung zurückzunehmen)</i></span>
              </b-col>
            </b-row>
          </b-container>
          <b-button class="button-primary" variant="primary" @click="infoShow = false" style="float: right; margin-top: 10px;"><strong>OK</strong></b-button>
        </b-modal>

        <h4>Münzauszahlung</h4>
        <br />

        <div v-if="showUser">
          <b-card header-tag="header" footer-tag="footer" id="checkin">
            <template #header>
              <h5 class="mb-0" style="float: left; padding-top: 6px;">Gefundener Teilnehmer</h5>
              <b-button class="button-primary" variant="primary" style="float: right; margin-left: 10px;" @click="showUser = false">X</b-button>
              <b-button class="button-primary" variant="primary" style="float: right;" @click="infoShow = true">Info</b-button>
            </template>
            <b-card-text>
              <b-table :items="[user]" :fields="fields" stacked small></b-table>
            </b-card-text>
            <template #footer>
              <b-container>
                <b-row cols="2" cols-sm="4" cols-md="5" cols-lg="5" class="text-center">
                  
                  <b-col id="col" @click="coinTouched('challenge-1')">
                    <div>Station 1</div>
                    <div>
                      <b-img id="coin" src="coin0.png" v-if="coin1Status == 0"></b-img>
                      <b-img id="coin" src="coin1.png" v-if="coin1Status == 1"></b-img>
                      <b-img id="coin" src="coin2.png" v-if="coin1Status == 2"></b-img>
                      <b-img id="coin" src="coin3.png" v-if="coin1Status == 3"></b-img>
                    </div>
                  </b-col>
                  <b-col id="col" @click="coinTouched('challenge-2')">
                    <div>Station 2</div>
                    <div>
                      <b-img id="coin" src="coin0.png" v-if="coin2Status == 0"></b-img>
                      <b-img id="coin" src="coin1.png" v-if="coin2Status == 1"></b-img>
                      <b-img id="coin" src="coin2.png" v-if="coin2Status == 2"></b-img>
                      <b-img id="coin" src="coin3.png" v-if="coin2Status == 3"></b-img>
                    </div>
                  </b-col>
                  <b-col id="col" @click="coinTouched('challenge-3')">
                    <div>Station 3</div>
                    <div>
                      <b-img id="coin" src="coin0.png" v-if="coin3Status == 0"></b-img>
                      <b-img id="coin" src="coin1.png" v-if="coin3Status == 1"></b-img>
                      <b-img id="coin" src="coin2.png" v-if="coin3Status == 2"></b-img>
                      <b-img id="coin" src="coin3.png" v-if="coin3Status == 3"></b-img>
                    </div>
                  </b-col>
                  <b-col id="col" @click="coinTouched('challenge-4')">
                    <div>Station 4</div>
                    <div>
                      <b-img id="coin" src="coin0.png" v-if="coin4Status == 0"></b-img>
                      <b-img id="coin" src="coin1.png" v-if="coin4Status == 1"></b-img>
                      <b-img id="coin" src="coin2.png" v-if="coin4Status == 2"></b-img>
                      <b-img id="coin" src="coin3.png" v-if="coin4Status == 3"></b-img>
                    </div>
                  </b-col>
                  <b-col id="col" @click="coinTouched('challenge-5')">
                    <div>Station 5</div>
                    <div>
                      <b-img id="coin" src="coin0.png" v-if="coin5Status == 0"></b-img>
                      <b-img id="coin" src="coin1.png" v-if="coin5Status == 1"></b-img>
                      <b-img id="coin" src="coin2.png" v-if="coin5Status == 2"></b-img>
                      <b-img id="coin" src="coin3.png" v-if="coin5Status == 3"></b-img>
                    </div>
                  </b-col>

                  <b-col id="col" @click="coinTouched('challenge-bonus1')" v-if="isBonusActive">
                    <div>Zusatz 1</div>
                    <div>
                      <b-img id="coin" src="coin2.png" v-if="coin1BonuStatus == 0"></b-img>
                      <b-img id="coin" src="coin2.png" v-if="coin1BonuStatus == 1"></b-img>
                      <b-img id="coin" src="coin2.png" v-if="coin1BonuStatus == 2"></b-img>
                      <b-img id="coin" src="coin3.png" v-if="coin1BonuStatus == 3"></b-img>
                    </div>
                  </b-col>
                  <b-col id="col" @click="coinTouched('challenge-bonus2')" v-if="isBonusActive">
                    <div>Zusatz 2</div>
                    <div>
                      <b-img id="coin" src="coin2.png" v-if="coin2BonuStatus == 0"></b-img>
                      <b-img id="coin" src="coin2.png" v-if="coin2BonuStatus == 1"></b-img>
                      <b-img id="coin" src="coin2.png" v-if="coin2BonuStatus == 2"></b-img>
                      <b-img id="coin" src="coin3.png" v-if="coin2BonuStatus == 3"></b-img>
                    </div>
                  </b-col>
                  <b-col id="col" @click="coinTouched('challenge-bonus3')" v-if="isBonusActive">
                    <div>Zusatz 3</div>
                    <div>
                      <b-img id="coin" src="coin2.png" v-if="coin3BonuStatus == 0"></b-img>
                      <b-img id="coin" src="coin2.png" v-if="coin3BonuStatus == 1"></b-img>
                      <b-img id="coin" src="coin2.png" v-if="coin3BonuStatus == 2"></b-img>
                      <b-img id="coin" src="coin3.png" v-if="coin3BonuStatus == 3"></b-img>
                    </div>
                  </b-col>

                </b-row>
              </b-container>
            </template>
          </b-card>
        </div>

        <div class="accordion" role="tablist" v-if="!showUser">
        <b-card no-body id="checkin">
            <b-tabs card active-nav-item-class="font-weight-bold">
                <b-tab title="QR Code" active>
                    <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit" id="cam"></qrcode-stream>
                    <b-card-footer>
                        <b-button v-if="camera == 'off'" class="button-primary" variant="primary" type="submit" size="lg" @click="turnCameraOn" style="width: 100%;">Starte Scannen</b-button>
                        <b-button v-else class="button-primary" variant="primary" type="submit" size="lg" @click="turnCameraOff" style="width: 100%;">Stoppe Scannen <b-spinner type="border" small></b-spinner></b-button>
                    </b-card-footer>
                </b-tab>
                <b-tab title="Zugangscode">
                    <div style="padding: 20px;">
                        <b-form-input v-model="code" type="search" size="lg" maxlength=8 placeholder="Zugangscode eingeben" ></b-form-input>
                    </div>
                    <b-card-footer>
                        <b-button :disabled="code.length == 0" class="button-primary" variant="primary" type="submit" size="lg" style="width: 100%;" @click="check">Suchen</b-button>
                    </b-card-footer>
                </b-tab>
            </b-tabs>
        </b-card>
        </div>

    </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  components: { QrcodeStream },
  data () {
    return {
      isValid: undefined,
      camera: 'off',
      code: "",
      coins: [],
      fields: ["Zugangscode", "Vorname", "Nachname", "Unternehmen", "E-Mail-Adresse", "Kundennummer", "Registrierung", "Zeitstempel"],
      user: {},
      isBonusActive: false,
      showUser: false,
      errorShow: false,
      infoShow: false,
      coin1Status: 0,
      coin2Status: 0,
      coin3Status: 0,
      coin4Status: 0,
      coin5Status: 0,
      coin1BonuStatus: 0,
      coin2BonuStatus: 0,
      coin3BonuStatus: 0,
    }
  },
  computed: {
    alidationPending () {
        return this.camera === 'off'
    },
    validationSuccess () {
        return this.isValid === true
    },
    validationFailure () {
        return this.isValid === false
    }
  },
  methods: {
    onInit (promise) {
        promise
            .catch(console.error)
            .then(this.resetValidationState)
    },
    resetValidationState () {
        this.isValid = undefined
    },
    async onDecode (content) {
        this.turnCameraOff()
        this.code = content
        this.check()
        await this.timeout(1)
    },
    turnCameraOn () {
      this.camera = 'auto'
    },
    turnCameraOff () {
      this.camera = 'off'
    },
    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    check() {
      this.kmo_searchUser(this.code).then(() => {
        if (Object.keys(this.$store.user).length === 0) {
          this.showUser = false
          this.errorShow = true
        } else {

          if (this.$store.user.wave == "1") {
            this.$store.user.wave = "Online"
            this.isBonusActive = true
          } else {
            this.$store.user.wave = "On-Site"
            this.isBonusActive = false
          }

          delete this.$store.user.userID
          delete this.$store.user.mailSend
          delete this.$store.user.slot
          delete this.$store.user.hasChanged

          this.user["Zugangscode"] = this.$store.user.eventID
          this.user["Vorname"] = this.$store.user.firstname
          this.user["Nachname"] = this.$store.user.lastname
          this.user["Unternehmen"] = this.$store.user.companyname
          this.user["E-Mail-Adresse"] = this.$store.user.mail
          this.user["Kundennummer"] = this.$store.user.customerID
          this.user["Registrierung"] = this.$store.user.wave
          this.user["Zeitstempel"] = this.$store.user.timestamp

          this.showUser = true
        }
      })
      this.updateCoins()
    },
    updateCoins() {
      this.coin1Status = 0
      this.coin2Status = 0
      this.coin3Status = 0
      this.coin4Status = 0
      this.coin5Status = 0
      this.coin1BonuStatus = 0
      this.coin2BonuStatus = 0
      this.coin3BonuStatus = 0
      this.kmo_refreshCoins(this.code).then(() => {
        this.coins = this.$store.coins
        this.coins.forEach(coin => {
          switch (coin.slot) {
            case "challenge-1": this.coin1Status = coin.status; break;
            case "challenge-2": this.coin2Status = coin.status; break;
            case "challenge-3": this.coin3Status = coin.status; break;
            case "challenge-4": this.coin4Status = coin.status; break;
            case "challenge-5": this.coin5Status = coin.status; break;
            case "challenge-bonus1": this.coin1BonuStatus = coin.status; break;
            case "challenge-bonus2": this.coin2BonuStatus = coin.status; break;
            case "challenge-bonus3": this.coin3BonuStatus = coin.status; break;
            default: break;
          }
        })
      })
    },
    coinTouched(coin) {

      var status = ""

      switch (coin) {
        case "challenge-1":
          if (this.coin1Status == "2") { status = "3" }
          if (this.coin1Status == "3") { status = "2" }
          break;
        case "challenge-2":
          if (this.coin2Status == "2") { status = "3" }
          if (this.coin2Status == "3") { status = "2" }
          break;
        case "challenge-3":
          if (this.coin3Status == "2") { status = "3" }
          if (this.coin3Status == "3") { status = "2" }
          break;
        case "challenge-4":
          if (this.coin4Status == "2") { status = "3" }
          if (this.coin4Status == "3") { status = "2" }
          break;
        case "challenge-5":
          if (this.coin5Status == "2") { status = "3" }
          if (this.coin5Status == "3") { status = "2" }
          break;
        
        case "challenge-bonus1":
          if (this.coin1BonuStatus == "0") { status = "3" }
          if (this.coin1BonuStatus == "1") { status = "3" }
          if (this.coin1BonuStatus == "2") { status = "3" }
          if (this.coin1BonuStatus == "3") { status = "2" }
          break;
        case "challenge-bonus2":
          if (this.coin2BonuStatus == "0") { status = "3" }
          if (this.coin2BonuStatus == "1") { status = "3" }
          if (this.coin2BonuStatus == "2") { status = "3" }
          if (this.coin2BonuStatus == "3") { status = "2" }
          break;
        case "challenge-bonus3":
          if (this.coin3BonuStatus == "0") { status = "3" }
          if (this.coin3BonuStatus == "1") { status = "3" }
          if (this.coin3BonuStatus == "2") { status = "3" }
          if (this.coin3BonuStatus == "3") { status = "2" }
          break;
        default:break;
      }

      if (status >= 2) {
        this.kmo_saveCoinStatus(this.$store.user.eventID, coin, status).then(() => {
          this.updateCoins()
        })
      }
    }
  }
}
</script>

<style scoped>
.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, .8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: green;
}
.validation-failure {
  color: red;
}
.button-primary {
    background-color: #ff8500 !important;
    border-color: #ff8500 !important;
}
.card-body {
    padding: 0px;
}
#checkin {
    max-width: 700px;
    margin: auto;
}
#cam {
    background-color: white;
}
.tab-active {
    background-color: green;
}
.tab {
    background-color: red;
}
#background {
  background-color: #E1E1E1;
}
#col {
  margin-bottom: 20px;
}
#coin {
  width: 100px;
}
</style>