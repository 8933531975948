<template>
    <div>
        <div><h4>Skipped Songs</h4></div>
        <Bar
            id="chart"
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
        /><br />
        <b-table class="textTable" :items="skippedTracks" :fields="fields" responsive bordered outlined small></b-table>
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'BarChart',
    components: {
        Bar
    },
    props: {
        chartId: {
            type: String,
            default: 'bar-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {}
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            skippedTracks: [],
            tracknames: [],
            trackcounts: [],
            songnames: [
                { file: 'track-01.mp3', name: '4 Walls'},
                { file: 'track-02.mp3', name: 'Be Sweet'},
                { file: 'track-03.mp3', name: 'Break on Through'},
                { file: 'track-04.mp3', name: 'Champion'},
                { file: 'track-05.mp3', name: 'Feel Alive'},
                { file: 'track-06.mp3', name: 'Flowers'},
                { file: 'track-07.mp3', name: 'Good Life'},
                { file: 'track-08.mp3', name: 'Living In A Haze'},
                { file: 'track-09.mp3', name: 'Mangos mit Chili'},
                { file: 'track-10.mp3', name: 'Me and My Guitar'},
                { file: 'track-11.mp3', name: 'Relax My Eyes'},
                { file: 'track-12.mp3', name: 'Taste like Summertime'},
                { file: 'track-13.mp3', name: 'The Feeling'},
                { file: 'track-14.mp3', name: 'Weekenders'},
                { file: 'track-15.mp3', name: 'Fast Forward'},
                { file: 'track-16.mp3', name: 'Forever Young'},
                { file: 'track-17.mp3', name: 'Good Feeling'},
                { file: 'track-18.mp3', name: 'Waiting Forever'},
                { file: 'track-19.mp3', name: 'As It Was'},
                { file: 'track-20.mp3', name: 'Born To Run'},
                { file: 'track-21.mp3', name: 'I Like You'},
                { file: 'track-22.mp3', name: 'Californication'},
                { file: 'track-23.mp3', name: 'Overdrive'},
                { file: 'track-24.mp3', name: 'Sweet Child O Mine'},
                { file: 'track-25.mp3', name: 'Sommerwind'},
                { file: 'track-26.mp3', name: 'Take Two'},
                { file: 'track-27.mp3', name: 'Dont Stop Belivin'},
                { file: 'track-28.mp3', name: 'Fast Car'},
                { file: 'track-29.mp3', name: 'Flashed Junk Mind'},
                { file: 'track-30.mp3', name: 'Im Still Standing'},
                { file: 'track-31.mp3', name: 'We Didnt Start The Fire'},
                { file: 'track-32.mp3', name: 'Livin On A Prayer'},
                { file: 'track-33.mp3', name: 'Under Pressure'},
                { file: 'track-34.mp3', name: 'Butterfly'},
                { file: 'track-35.mp3', name: 'Indigo'},
                { file: 'track-36.mp3', name: 'Mai Tai'},
                { file: 'track-37.mp3', name: 'Viva La Vida'},
                { file: 'track-38.mp3', name: 'Sweet Child O Mine'},
                { file: 'track-39.mp3', name: 'Africa'},
                { file: 'track-40.mp3', name: 'Yet To Come'},
                { file: 'track-41.mp3', name: 'Im On It'}
            ],
            chartData: {},
            fields: [
                { key: 'trackID', label: 'Ranking' },
                { key: 'trackname', label: 'Songname' },
                { key: 'count', label: 'Skipped' },
            ],
            chartOptions: {
                responsive: true, 
                maintainAspectRatio: false,
                scales: {
                    y: {
                        title: {
                            color: 'black',
                            display: true,
                            text: 'Count'
                        },
                        min: 0,
                        ticks: {
                            stepSize: 1
                        }
                    },
                    x: {
                        title: {
                            color: 'black',
                            display: true,
                            text: 'Ranking'
                        }
                    }
                }
            }
        }
    },
    created() {
        this.refreshSkippedTracks()
    },
    methods: {
        refreshSkippedTracks() {
            this.kmo_refreshSkippedTracks().then(() => {
                this.skippedTracks = this.$store.skippedTracks

                var index = 1
                this.tracknames = []
                this.trackcount = []
                this.skippedTracks.forEach(track => {
                    track.trackID = index
                    this.songnames.find(song => {
                        if (song.file == track.trackname) {
                            track.trackname = song.name
                        }
                    })
                    this.tracknames.push(index)
                    this.trackcounts.push(track.count)
                    index++
                })

                this.chartData = {
                    labels: this.tracknames,
                    datasets: [
                        {
                            label: 'Skipped',
                            backgroundColor: '#AA6C43',
                            data: this.trackcounts
                        }
                    ]
                }
            })
        }
    }
}
</script>

<style>
#chart {
    background-color: white;
}
#color {
    background-color: #AA6C43;
    font-size: 13px;
}
.textTable {
    background-color: white;
}
</style>