<template>
    <div>

        <!-- New / Edit Waypoint Modal -->
        <b-modal ref="editSpeechModal" body-bg-variant="light" size="xl" hide-footer centered>
            <b-alert :show="alertShow" :variant="alertVariant">{{alertText}}</b-alert>
            <template v-if="this.isNewEpisode" #modal-header="{ }">
                <h4>New Episode</h4>
            </template>
            <template v-else #modal-header="{ }">
                <h4>Edit Episode</h4>
            </template>

            <b-form v-if="selectedSpeech">

                <b-form-select v-model="selectedSpeech.vehicleGroupID" :options="vehicleList" style="width: 280px" @change="loadPodcast()"></b-form-select>
                <br /><br />

                <label><strong>Name</strong></label>
                <b-form-input v-model="selectedSpeech.speechName"></b-form-input>
                <br />
                
                <label><strong>German</strong></label>
                <b-form-textarea v-model="selectedSpeech['de_DE']" rows="5"></b-form-textarea>
                <br />

                <!-- <label><strong>Englisch</strong></label>
                <b-form-textarea v-model="selectedSpeech['en_EN']" rows="5"></b-form-textarea>
                <br /> -->
                
                <b-button class="button-secondary" style="float: left" @click="hideEditSpeechModal()">Cancel</b-button>
                <b-button v-if="this.isNewEpisode" class="button-primary" style="float: right" @click="saveSpeech()">Save</b-button>
                <b-button v-else class="button-primary" style="float: right" @click="updateSpeech()">Save</b-button>
                
            </b-form>
        </b-modal>

        <!-- Upload MP3 -->
        <b-modal ref="uploadModal" body-bg-variant="light" hide-footer centered>
            <b-alert :show="alertShow" :variant="alertVariant">{{alertText}}</b-alert>
            <template #modal-header="{}">
                <h4>Upload MP3</h4>
            </template>

            <b-form v-if="selectedSpeech">

                <b>{{ selectedSpeech.speechName }} - <i>{{ uploadLanguageName }}</i></b>
                <br /><br />
                <p>{{ selectedSpeech[uploadLanguageKey] }}</p>
                <br />
                <b-form-file
                v-model="uploadFile"
                :state="Boolean(uploadFile)"
                placeholder="Choose a MP3 file..."
                drop-placeholder="Drop MP3 file here..."
                accept=".mp3"
                ></b-form-file>
                <br /><br />
                <b-button class="button-secondary" style="float: left" @click="hideUpload()" :disabled="disabled">Cancel</b-button>
                <b-button v-if="!disabled" class="button-primary" style="float: right" @click="uploadSelectedFile()">Upload File</b-button>
                <b-spinner v-if="disabled" label="Spinning" style="float: right"></b-spinner>
                
            </b-form>
        </b-modal>

        <!-- Generate MP3 -->
        <b-modal ref="generateModal" body-bg-variant="light" hide-footer centered>
            <template #modal-header="{}">
                <h4>Generate MP3 with AI</h4>
            </template>

            <b-form v-if="selectedSpeech">

                <b>{{ selectedSpeech.speechName }} - <i>{{ uploadLanguageName }}</i></b>
                <br /><br />
                <p>{{ selectedSpeech[uploadLanguageKey] }}</p>
                <br />
                <div style="margin-bottom: 10px;">
                    <b>ElevenLabs Account</b><br />
                    Characters used: {{ speechInfoCount }} / {{ speechInfoLimit }}<br />
                    Characters left: <b>{{ speechInfoLimit-speechInfoCount }} ({{ Math.round(((speechInfoLimit-speechInfoCount) * 100) / speechInfoLimit) }}%)</b><br />
                    
                </div>
                <br />
                <b-button class="button-secondary" style="float: left" @click="hideGenerate()" :disabled="disabled">Cancel</b-button>
                <b-button v-if="!disabled" class="button-primary" style="float: right" @click="generateSpeech()">Generate File</b-button>
                <b-spinner v-if="disabled" label="Spinning" style="float: right"></b-spinner>
                
            </b-form>
        </b-modal>

        <!-- Delete MP3 -->
        <b-modal ref="deleteModal" body-bg-variant="light" hide-footer centered>
            <template #modal-header="{}">
                <h4>Delete MP3</h4>
            </template>

            <b-form v-if="selectedSpeech">

                <b>{{ selectedSpeech.speechName }} - <i>{{ uploadLanguageName }}</i></b>
                <br /><br />
                <b-button class="button-secondary" style="float: left" @click="hideDelete()" :disabled="disabled">Cancel</b-button>
                <b-button variant="danger" style="float: right" @click="deleteSpeech(selectedSpeech.speechKey, uploadLanguageKey)">Delete Audio-File</b-button>
                
            </b-form>
        </b-modal>

        <div v-if="showAudioPlayer" class="fixed-top" style="margin-top: 60px; text-align: center;">
            <audio :src="audioPlayerFile" autoplay controls>Dein Browser unterstützt keine HTML Audio</audio>
        </div>

        <h4>Podcast</h4>
        <b-form-select v-model="selectedVehicleGroupID" :options="vehicleList" style="width: 280px" @change="loadPodcast()"></b-form-select>
        <div v-if="selectedVehicleGroupID">
            <b-form-input
                style="margin-top: 14px; margin-bottom: 14px"
                v-model="filter"
                type="search"
                size="lg"
                placeholder="Volltextsuche"
            ></b-form-input>

            <b-button class="button-primary" @click="newSpeech()" style="float: right">+ Episode</b-button><br /><br />

            <b-table class="podcastTable" :items="speechList" :fields="fields" :filter="filter" responsive bordered outlined stacked="lg">

                <template #cell(de_DE)="row">
                    <span v-if="row.item['de_DE']">
                        {{ row.item['de_DE'] }}<br />
                        <div style="float: right" v-if="row.item['de_DE-file']">
                            <b-button class="button-primary" style="margin: 10px 6px 0px 10px;" @click="playSpeech(row.item['de_DE-file'])" :disabled="disabled"><b-icon icon="music-note-beamed" style="margin-right: 10px;"></b-icon>Play / Stop</b-button>
                            <b-button class="button-primary" style="margin: 10px 6px 0px 0px;" @click="downloadSpeech(row.item['de_DE-file'])" :disabled="disabled"><b-icon icon="download"></b-icon></b-button>
                            <b-button class="button-danger" style="margin: 10px 6px 0px 0px;" @click="showDelete(row.item, 'de_DE')" :disabled="disabled"><b-icon icon="trash"></b-icon></b-button>
                        </div>
                        <div style="float: right" v-else>
                            <b-button class="button-secondary" style="margin: 10px 6px 0px 0px;" @click="showUpload(row.item, 'de_DE')" :disabled="disabled">Upload MP3</b-button>
                            <b-button class="button-secondary" style="margin: 10px 6px 0px 0px;" @click="showGenerate(row.item, 'de_DE')" :disabled="disabled">Generate with AI</b-button>
                        </div>
                    </span>
                </template>

                <template #cell(en_EN)="row">
                    <span v-if="row.item['en_EN']">
                        {{ row.item['en_EN'] }}<br />
                        <div style="float: right" v-if="row.item['en_EN-file']">
                            <b-button class="button-primary" style="margin: 10px 6px 0px 10px;" @click="playSpeech(row.item['en_EN-file'])" :disabled="disabled"><b-icon icon="music-note-beamed" style="margin-right: 10px;"></b-icon>Play / Stop</b-button>
                            <b-button class="button-primary" style="margin: 10px 6px 0px 0px;" @click="downloadSpeech(row.item['en_EN-file'])" :disabled="disabled"><b-icon icon="download"></b-icon></b-button>
                            <b-button class="button-danger" style="margin: 10px 6px 0px 0px;" @click="showDelete(row.item, 'en_EN')" :disabled="disabled"><b-icon icon="trash"></b-icon></b-button>
                        </div>
                        <div style="float: right" v-else>
                            <b-button class="button-secondary" style="margin: 10px 6px 0px 0px;" @click="showUpload(row.item, 'en_EN')" :disabled="disabled">Upload MP3</b-button>
                            <b-button class="button-secondary" style="margin: 10px 6px 0px 0px;" @click="showGenerate(row.item, 'en_EN')" :disabled="disabled">Generate with AI</b-button>
                        </div>
                    </span>
                </template>

                <template #cell(edit)="row">
                    <b-button class="button-primary" style="float: right" size="sm" @click="editSpeech(row.item)" :disabled="disabled"><b-icon icon="pencil-square"></b-icon></b-button>
                </template>
                
            </b-table>

        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            speechList: [],
            filter: null,
            selectedSpeech: null,
            selectedVehicleGroupID: null,
            selectedVehicleGroupIDEdit: 0,
            selectedVehicleGroup: null,
            vehicleList: [],
            uploadLanguageKey: null,
            uploadLanguageName: null,
            uploadFile: null,
            alertShow: false,
            alertText: "",
            alertVariant: "",
            isNewEpisode: false,
            showAudioPlayer: false,
            audioPlayerFile: null,
            audioPlayerFilename: "",
            disabled: false,
            speechInfoCount: 0,
            speechInfoLimit: 0,
            allVehicleGroups: [],
            fields: [
                { key: 'vehicleName', label: 'Vehicle', thStyle: { width: "200px" }},
                { key: 'speechName', label: 'Name', thStyle: { width: "200px" }},
                { key: 'de_DE', label: 'German'},
                { key: 'edit', label: 'Edit', thStyle: { width: "60px" }},
            ],
        }
    },
    methods: {
        refreshVehicleGroups() {
            this.kmo_refreshVehicleGroups().then(() => {
                this.vehicleList = []
                this.vehicleList.push({
                    value: null,
                    text: "-- Select Vehicle"
                })
                this.$store.vehicleGroups.forEach(vehicleGroup => {
                    this.vehicleList.push({
                        value: vehicleGroup.vehicleGroupID,
                        text: vehicleGroup.name
                    })
                })
            })
        },
        refreshSpeechList() {
            this.refreshSpeechInfo()
            this.kmo_refreshSpeechList().then(() => {
                this.speechList = this.$store.speechList
                var speechListFiltered = []
                this.speechList.forEach(speech => {
                    if (speech.vehicleGroupID == this.selectedVehicleGroupID) {
                        speechListFiltered.push(speech)
                    }
                })
                this.speechList = speechListFiltered
            })
        },
        loadPodcast() {
            this.selectedVehicleGroup = null
            this.vehicleList.forEach(vehicle => {
                if (vehicle.value == this.selectedVehicleGroupID) {
                    this.selectedVehicleGroup = vehicle
                }
            })
            this.refreshSpeechList()
        },
        newSpeech() {
            this.selectedSpeech = {
                vehicleGroupID: this.selectedVehicleGroupID,
                speechName: "",
                de_DE: "",
                en_EN: ""
            }
            this.isNewEpisode = true
            this.alertShow = false
            this.$refs['editSpeechModal'].show()
        },
        editSpeech(speech) {
            this.selectedSpeech = JSON.parse(JSON.stringify(speech)) // Copy object
            this.isNewEpisode = false
            this.alertShow = false
            this.$refs['editSpeechModal'].show()
        },
        hideEditSpeechModal() {
            this.alertShow = false
            this.$refs['editSpeechModal'].hide()
        },
        saveSpeech() {
            if (this.selectedSpeech.vehicleGroupID && this.selectedSpeech.speechName && this.selectedSpeech.de_DE) {
                this.kmo_saveSpeech(this.selectedSpeech.vehicleGroupID, this.selectedSpeech.speechName, this.selectedSpeech['de_DE'], this.selectedSpeech['en_EN']).then((result) => {
                    if (result[0]['affectedRows'] == 1) {
                        this.$refs['editSpeechModal'].hide()
                        this.refreshSpeechList()
                    } else {
                        this.alertText = "Server Error"
                        this.alertVariant = "danger"
                        this.alertShow = true
                    }
                })
            } else {
                this.alertText = "Fields can´t be empty"
                this.alertVariant = "danger"
                this.alertShow = true
            }
        },
        updateSpeech() {
            if (this.selectedSpeech.vehicleGroupID && this.selectedSpeech.speechKey && this.selectedSpeech.speechName && this.selectedSpeech.de_DE) {
                this.kmo_updateSpeech(this.selectedSpeech.speechKey, this.selectedSpeech.vehicleGroupID, this.selectedSpeech.speechName, this.selectedSpeech['de_DE'], this.selectedSpeech['en_EN']).then((result) => {
                    if (result[0]['affectedRows'] == 1) {
                        this.deleteSpeech(this.selectedSpeech.speechKey, "de_DE")
                        this.$refs['editSpeechModal'].hide()
                        this.refreshSpeechList()
                    } else {
                        this.alertText = "Server Error"
                        this.alertVariant = "danger"
                        this.alertShow = true
                    }
                })
            } else {
                this.alertText = "Fields can´t be empty"
                this.alertVariant = "danger"
                this.alertShow = true
            }
        },
        showUpload(speech, languageKey) {
            this.selectedSpeech = speech
            this.uploadLanguageKey = languageKey
            switch (languageKey) {
                case "de_DE": this.uploadLanguageName = "German"; break;
                case "en_EN": this.uploadLanguageName = "English"; break;
            }
            this.$refs['uploadModal'].show()
        },
        hideUpload() {
            this.$refs['uploadModal'].hide()
        },
        showGenerate(speech, languageKey) {
            this.selectedSpeech = speech
            this.uploadLanguageKey = languageKey
            switch (languageKey) {
                case "de_DE": this.uploadLanguageName = "German"; break;
                case "en_EN": this.uploadLanguageName = "English"; break;
            }
            this.$refs['generateModal'].show()
        },
        hideGenerate() {
            this.$refs['generateModal'].hide()
        },
        showDelete(speech, languageKey) {
            this.selectedSpeech = speech
            this.uploadLanguageKey = languageKey
            switch (languageKey) {
                case "de_DE": this.uploadLanguageName = "German"; break;
                case "en_EN": this.uploadLanguageName = "English"; break;
            }
            this.$refs['deleteModal'].show()
        },
        hideDelete() {
            this.$refs['deleteModal'].hide()
        },
        uploadSelectedFile () {
            if (this.uploadFile) {
                this.disabled = true
                this.kmo_uploadSpeech(this.selectedSpeech.speechKey, this.uploadLanguageKey, this.uploadFile).then((result) => {
                    this.disabled = false
                    if (result[0]['affectedRows'] == 1) {
                        this.$refs['uploadModal'].hide()
                        this.refreshSpeechList()
                    } else {
                        this.alertText = "Server Error"
                        this.alertVariant = "danger"
                        this.alertShow = true
                    }
                })
            } else {
                this.alertText = "Missing File"
                this.alertVariant = "danger"
                this.alertShow = true
            }
        },
        playSpeech(filename) {
            if (filename == this.audioPlayerFilename) {
                this.audioPlayerFilename = ""
                this.showAudioPlayer = false
                return
            }
            this.kmo_playSpeech(filename).then((result) => {
                const blob = new Blob([result.data], { type: "audio/mpeg" })
                this.audioPlayerFile = URL.createObjectURL(blob)
                this.audioPlayerFilename = filename
                this.showAudioPlayer = true
            })
        },
        downloadSpeech(filename) {
            this.kmo_playSpeech(filename).then((result) => {
                const blob = new Blob([result.data], { type: "audio/mpeg" });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = filename;
                link.click();

                URL.revokeObjectURL(link.href);
            })
        },
        deleteSpeech(speechKey, languageKey) {
            this.kmo_deleteSpeech(speechKey, languageKey).then(() => {
                this.refreshSpeechList()
                this.hideDelete()
            })
        },
        generateSpeech() {
            this.disabled = true
            this.kmo_generateSpeech(this.selectedSpeech.speechKey, this.uploadLanguageKey, this.selectedSpeech[this.uploadLanguageKey]).then(() => {
                this.refreshSpeechList()
                this.disabled = false
                this.hideGenerate()
            })
        },
        refreshSpeechInfo() {
            this.kmo_generateSpeechInfo().then((result) => {
                this.speechInfoCount = result.count
                this.speechInfoLimit = result.limit
            })
        },
        isValidName(e) {
            let char = String.fromCharCode(e.keyCode)
            if(/^[A-Za-z0-9 ]+$/.test(char)) return true
            else e.preventDefault()
        }
    },
    created() {
        this.refreshVehicleGroups()
    }
}
</script>

<style scoped>
.button-primary {
    background-color: #AA6C43 !important;
    border-color: #AA6C43 !important;
}
.button-secondary {
    background-color: white !important;
    border-color: #AA6C43 !important;
    color: #AA6C43 !important;
}
.button-danger {
    background-color: red !important;
    border-color: red !important;
    /* color: red !important; */
}
.podcastTable {
    background-color: white;
}
</style>