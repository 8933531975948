<template>
    <div>
        <div><h4>Registrierungen</h4></div>
        <LineChartGenerator
            id="chart"
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
        /><br />
        <b-list-group style="float: right; width: 240px; margin-bottom: 20px;" class="small">
            <b-list-group-item class="d-flex justify-content-between align-items-center py-2">
                Montag 13.03.
                <b-badge id="day1" variant="primary" pill>{{ day1Count }}</b-badge>
            </b-list-group-item>

            <b-list-group-item class="d-flex justify-content-between align-items-center py-2">
                Dienstag 14.03.
                <b-badge id="day2" variant="primary" pill>{{ day2Count }}</b-badge>
            </b-list-group-item>

            <b-list-group-item class="d-flex justify-content-between align-items-center py-2">
                Mittwoch 15.03.
                <b-badge id="day3" variant="primary" pill>{{ day3Count }}</b-badge>
            </b-list-group-item>

            <b-list-group-item class="d-flex justify-content-between align-items-center py-2">
                Donnerstag 16.03.
                <b-badge id="day4" variant="primary" pill>{{ day4Count }}</b-badge>
            </b-list-group-item>

            <b-list-group-item class="d-flex justify-content-between align-items-center py-2">
                Freitag 17.03.
                <b-badge id="day5" variant="primary" pill>{{ day5Count }}</b-badge>
            </b-list-group-item>

            <b-list-group-item class="d-flex justify-content-between align-items-center py-2">
                <b>Gesamtanzahl</b>
                <b-badge id="sum" variant="primary" pill>{{ registerList.length }}</b-badge>
            </b-list-group-item>
        </b-list-group>
    </div>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

export default {
    name: 'LineChart',
    components: {
        LineChartGenerator
    },
    props: {
        chartId: {
            type: String,
            default: 'line-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {}
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            registerList: [],
            timer: '',
            day1Data: [],
            day2Data: [],
            day3Data: [],
            day4Data: [],
            day5Data: [],
            day1Count: 0,
            day2Count: 0,
            day3Count: 0,
            day4Count: 0,
            day5Count: 0,
            loaded: false,
            chartData: {},
            chartOptions: {
                responsive: true, 
                maintainAspectRatio: false,
                scales: {
                    y: {
                        title: {
                            color: 'black',
                            display: true,
                            text: 'Anzahl'
                        }
                    },
                    x: {
                        title: {
                            color: 'black',
                            display: true,
                            text: 'Uhrzeit'
                        }
                    }
                }
            }
        }
    },
    created() {
        this.refreshUsers()
        this.timer = setInterval(this.refreshUsers, 10000)
    },
    beforeDestroy() {
        this.cancelAutoUpdate()
    },
    methods: {
        cancelAutoUpdate () {
            clearInterval(this.timer)
        },
        refreshUsers() {
            this.day1Data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            this.day2Data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            this.day3Data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            this.day4Data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            this.day5Data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            this.day1Count = 0
            this.day2Count = 0
            this.day3Count = 0
            this.day4Count = 0
            this.day5Count = 0
            this.loaded = false
            this.kmo_refreshUsers().then(() => {
                this.registerList = []
                this.$store.databaseUsers.forEach(register => {
                    var daySlot = []
                    if (register.timestamp.includes("2023-03-13")) {
                        daySlot = this.day1Data
                        this.day1Count += 1
                    }
                    if (register.timestamp.includes("2023-03-14")) {
                        daySlot = this.day2Data
                        this.day2Count += 1
                    }
                    if (register.timestamp.includes("2023-03-15")) {
                        daySlot = this.day3Data
                        this.day3Count += 1
                    }
                    if (register.timestamp.includes("2023-03-16")) {
                        daySlot = this.day4Data
                        this.day4Count += 1
                    }
                    if (register.timestamp.includes("2023-03-17")) {
                        daySlot = this.day5Data
                        this.day5Count += 1
                    }
                    var timeSlot = register.timestamp.substring(11, 19)
                    if (timeSlot >= "06:30:00" && timeSlot < "07:29:59") { daySlot[0] += 1 }
                    if (timeSlot >= "07:30:00" && timeSlot < "08:29:59") { daySlot[1] += 1 }
                    if (timeSlot >= "08:30:00" && timeSlot < "09:29:59") { daySlot[2] += 1 }
                    if (timeSlot >= "09:30:00" && timeSlot < "10:29:59") { daySlot[3] += 1 }
                    if (timeSlot >= "10:30:00" && timeSlot < "11:29:59") { daySlot[4] += 1 }
                    if (timeSlot >= "11:30:00" && timeSlot < "12:29:59") { daySlot[5] += 1 }
                    if (timeSlot >= "12:30:00" && timeSlot < "13:29:59") { daySlot[6] += 1 }
                    if (timeSlot >= "13:30:00" && timeSlot < "14:29:59") { daySlot[7] += 1 }
                    if (timeSlot >= "14:30:00" && timeSlot < "15:29:59") { daySlot[8] += 1 }
                    if (timeSlot >= "15:30:00" && timeSlot < "16:29:59") { daySlot[9] += 1 }
                    if (timeSlot >= "16:30:00" && timeSlot < "17:29:59") { daySlot[10] += 1 }
                    if (timeSlot >= "17:30:00" && timeSlot < "18:29:59") { daySlot[11] += 1 }
                    if (timeSlot >= "18:30:00" && timeSlot < "19:29:59") { daySlot[12] += 1 }

                    if (register.preregistered == "0" || (register.preregistered == "1" && register.timestamp)) {
                        this.registerList.push(register)
                    }
                })
                this.chartData = {
                    labels: [ '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19' ],
                    datasets: [
                        {
                            label: '13.03.',
                            backgroundColor: '#AA6C43',
                            data: this.day1Data
                        },
                        {
                            label: '14.03.',
                            backgroundColor: '#ff8500',
                            data: this.day2Data
                        },
                        {
                            label: '15.03.',
                            backgroundColor: '#cc0033',
                            data: this.day3Data
                        },
                        {
                            label: '16.03.',
                            backgroundColor: '#176fa4',
                            data: this.day4Data
                        },
                        {
                            label: '17.03.',
                            backgroundColor: '#b2ded8',
                            data: this.day5Data
                        }
                    ]
                }
                this.loaded = true
            })
        }
    }
}
</script>

<style>
#chart {
    background-color: white;
}
#online {
    background-color: darkgray;
    font-size: 13px;
}
#day1 {
    background-color: #AA6C43;
    font-size: 13px;
}
#day2 {
    background-color: #ff8500;
    font-size: 13px;
}
#day3 {
    background-color: #cc0033;
    font-size: 13px;
}
#day4 {
    background-color: #176fa4;
    font-size: 13px;
}
#day5 {
    background-color: #b2ded8;
    font-size: 13px;
}
#sum {
    background-color: black;
    font-size: 13px;
}
</style>