<template>
    <div>
        <div style="float: left"><h4>Vehicles</h4></div>
        <div style="float: right">Amount: <b>{{ vehicles.length }}</b></div>
        <b-form-input
            v-model="filter"
            type="search"
            size="lg"
            placeholder="Search"
        ></b-form-input><br >
        <b-table responsive small striped sort-icon-left hover :items="vehicles" :fields="fields" :filter="filter" id="table">
        </b-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            vehicles: [],
            filter: null,
            fields: [
                { key: 'vehicleID', label: 'Vehicle ID (QR-Code)', sortable: true },
                { key: 'vehicleGroup', label: 'Vehicle Group', sortable: true },
                { key: 'plate', label: 'License Plate', sortable: true },
                { key: 'vin', label: 'VIN Number', sortable: true },
            ]
        }
    },
    created() {
        this.refreshVehicles()
    },
    methods: {
        refreshVehicles() {
            this.kmo_refreshVehicles().then(() => {
                this.vehicles = this.$store.vehicles
            })
        }
    }
}
</script>

<style>
#table {
    background-color: white;
}
.button-primary {
    background-color: #ff8500 !important;
    border-color: #ff8500 !important;
}
</style>