<template>
    <div>
        <h3>Survey Comments</h3>
        <b-form-input
            v-model="filter"
            type="search"
            size="lg"
            placeholder="Search for wave, market or comment"
        ></b-form-input><br />
        <b-table responsive small striped sort-icon-left hover :items="surveyComments" :fields="fields" :filter="filter">
            <template v-slot:cell(translated)="data">
                <a :href="data.item.translateURL" target="_blank">Translate</a>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            surveyComments: [],
            filter: null,
            fields: [
                { key: 'wave', label: 'Wave', sortable: true },
                { key: 'market', label: 'Market', sortable: true },
                { key: 'comment', label: 'Comment' },
                { key: 'translated', label: '' }
            ]
        }
    },
    methods: {
        refreshSurveyComments() {
            this.kmo_refreshSurveyComments().then(() => {
                this.surveyComments = this.$store.surveyComments

                this.surveyComments.forEach(comment => {
                    comment['translateURL'] = "https://translate.google.com/m/translate#auto/en/" + encodeURI(comment['comment'])
                });
            })
        }
    },
    created() {
        this.refreshSurveyComments()
    }
}
</script>

<style>

</style>