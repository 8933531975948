import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        databaseUsers: [],
        databaseHeartbeat: [],
        databaseLanguages: [],
        events: [],
        users: [],
        passed: [],
        awards: [],
        settings: [],
        markets: [],
        usersCount: [],
        passedCount: [],
        stationRating: [],
        surveyComments: [],
        routes: [],
        speechList: [],
        audioFile: null,
        poiList: [],
        coins: [],
        coinList: [],
        coinSum: [],
        slotTimes: [],
        answers: [],
        user: null,
        vehicles: [],
        vehicleGroups: [],
        consents: [],
        allPois: [],
        allReachedPois: [],
        skippedTracks: [],
        feedbacks: [],
        activeComponent: "",
    },
    getters: {
        databaseUsers: state => {
            return state.databaseUsers;
        },
        databaseHeartbeat: state => {
            return state.databaseHeartbeat;
        },
        databaseLanguages: state => {
            return state.databaseLanguages;
        },
        events: state => {
            return state.events;
        },
        users: state => {
            return state.users;
        },
        stations: state => {
            return state.stations;
        },
        passed: state => {
            return state.passed;
        },
        awards: state => {
            return state.awards;
        },
        settings: state => {
            return state.settings;
        },
        markets: state => {
            return state.markets;
        },
        usersCount: state => {
            return state.usersCount;
        },
        passedCount: state => {
            return state.passedCount;
        },
        stationRating: state => {
            return state.stationRating;
        },
        surveyComments: state => {
            return state.surveyComments;
        },
        routes: state => {
            return state.routes;
        },
        speechList: state => {
            return state.speechList;
        },
        audioFile: state => {
            return state.audioFile;
        },
        poiList: state => {
            return state.poiList;
        },
        coins: state => {
            return state.coins;
        },
        coinList: state => {
            return state.coinList;
        },
        coinSum: state => {
            return state.coinSum;
        },
        slotTimes: state => {
            return state.slotTimes;
        },
        answers: state => {
            return state.answers;
        },
        user: state => {
            return state.user;
        },
        vehicles: state => {
            return state.vehicles;
        },
        vehicleGroups: state => {
            return state.vehicleGroups;
        },
        consents: state => {
            return state.consents;
        },
        allPois: state => {
            return state.allPois;
        },
        allReachedPois: state => {
            return state.allReachedPois;
        },
        skippedTracks: state => {
            return state.skippedTracks;
        },
    },
    mutations: {
        
    }
});