<template>
    <div>
        <div style="float: left"><h4>Events</h4></div>
        <b-form-input
            v-model="filter"
            type="search"
            size="lg"
            placeholder="Search"
        ></b-form-input><br >
        <b-table responsive small striped sort-icon-left hover :items="events" :fields="fields" :filter="filter" id="table">
        </b-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            events: [],
            filter: null,
            fields: [
                { key: 'eventID', label: 'EventID', sortable: true },
                { key: 'eventName', label: 'Name', sortable: true },
                { key: 'days', label: 'Days', sortable: true },
            ]
        }
    },
    created() {
        this.refreshEvents()
    },
    methods: {
        refreshEvents() {
            this.kmo_refreshEvents().then(() => {
                this.events = this.$store.events
                this.events.forEach(event => {
                    event.days = event.days[0] + " - " + event.days[event.days.length-1]
                })
            })
        }
    }
}
</script>

<style>
#table {
    background-color: white;
}
.button-primary {
    background-color: #ff8500 !important;
    border-color: #ff8500 !important;
}
</style>