<template>
    <div>
        <div style="float: left"><h4>Live Stations</h4></div>
        <div style="float: right"><div>Checked-In: <b>{{ checkinCount }}</b></div></div>
        <b-table responsive small striped sort-icon-left hover :items="stations" :fields="fields">
            <template v-slot:cell(checkinActive)="data">
                <b-form-checkbox
                    v-model="data.item.checkinActive"
                    @input="checkboxChanged(data.item, data.item.checkinActive)"
                ></b-form-checkbox>
                <!-- v-if="data.item.stationType != 'station-workshop'" -->
            </template>
            <!-- <template v-slot:cell(action)="data">
                <b-button
                    variant="info"
                    v-if="data.item.stationType=='station-workshop'"
                    @click="checkoutUser(data.item)"
                >Reset</b-button>
            </template> -->
        </b-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            stations: [],
            intervall: "",
            updateIsActive: true,
            checkinCount: 0,
            fields: [
                { key: 'stationKey', label: 'NFC', sortable: true },
                { key: 'backendname', label: 'Name'},
                { key: 'pax', label: 'User Limit' },
                { key: 'checkedin', label: 'Users', sortable: true },
                { key: 'checkinActive', label: 'Open', sortable: true },
                { key: 'content', label: 'Status' }
            ]
        }
    },
    methods: {
        startTimer() {
            this.intervall = setInterval(function() {
                if (this.updateIsActive) {
                    this.refreshLiveStations()
                }
            }.bind(this), 5000);
        },
        refreshLiveStations() {
            this.kmo_refreshLiveStations().then(() => {
                this.stations = this.$store.stations

                var userCount = 0

                this.stations.forEach(station => {
                    
                    userCount += parseInt(station['checkedin'])

                    switch (station['backendname']) {
                        case "workshop-product-1": station['backendname'] = "Leon Experience 1"; break;
                        case "workshop-product-2": station['backendname'] = "Leon Experience 2"; break;
                        case "workshop-compare-1": station['backendname'] = "Competitor Experience 1"; break;
                        case "workshop-compare-2": station['backendname'] = "Competitor Experience 2"; break;
                        case "connectivity": station['backendname'] = "Connectivity"; break;
                        case "leon-ar-1": station['backendname'] = "Assistance Systems 1"; break;
                        case "leon-ar-2": station['backendname'] = "Assistance Systems 2"; break;
                        case "table-customer": station['backendname'] = "Go electric!"; break;
                        case "accessories": station['backendname'] = "Leon Accessories"; break;
                        case "sportstourer": station['backendname'] = "Leon Sportstourer"; break;
                        default: break;
                    }

                    if (station['pax'] == "0") {
                        station['pax'] = "unlimited" // ∞
                    }
                    if (station['checkedin'] == "0") {
                        station['checkedin'] = ""
                    }
                    if (station['checkinActive'] == "1") {
                        station['checkinActive'] = true
                    } else {
                        station['checkinActive'] = false
                    }
                    if (station['group'] == "workshop-product") {
                        switch (station['content']) {
                            case "0":
                                if (station['checkinActive'] == "1") {
                                    station['content'] = "";
                                } else {
                                    station['content'] = "1/7 Introduction";
                                }
                                break;
                            case "1": station['content'] = "2/7 Pre-Test"; break;
                            case "2": station['content'] = "3/7 Hands-On"; break;
                            case "3": station['content'] = "4/7 Product Feedback"; break;
                            case "4": station['content'] = "5/7 Post-Test"; break;
                            case "5": station['content'] = "6/7 Evaluation"; break;
                            case "6": station['content'] = "7/7 Finished"; break;
                            default: break;
                        }
                    }
                    if (station['group'] == "workshop-compare") {
                        switch (station['content']) {
                            case "0":
                                if (station['checkinActive'] == "1") {
                                    station['content'] = "";
                                } else {
                                    station['content'] = "1/4 Introduction";
                                }
                                break;
                            case "1": station['content'] = "2/4 Hands-On"; break;
                            case "2": station['content'] = "3/4 Evaluation"; break;
                            case "3": station['content'] = "4/4 Finished"; break;
                            default: break;
                        }
                    }
                });

                this.checkinCount = userCount
            })
        },
        checkboxChanged(station, active) {
            this.kmo_stationCheckin(station.stationKey, active).then((response) => {
                console.log(response)
            });
        }
    },
    created() {
        this.updateIsActive = true
        this.refreshLiveStations()
        this.startTimer()
    },
    beforeDestroy() {
        this.updateIsActive = false
    }
}
</script>

<style>

</style>