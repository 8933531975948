<template>
    <div>
        <div style="float: left"><h4>Live Users</h4></div>
        <div style="float: right">Checked-In: <b>{{ checkinCount }} / {{ loginCount }}</b></div>
        <b-form-input
            v-model="filter"
            type="search"
            size="lg"
            placeholder="Search for nfcID, name, etc."
        ></b-form-input><br >
        <b-table responsive small striped sort-icon-left hover :items="users" :fields="fields" :filter="filter">
            <template v-slot:cell(checkout)="data">
                <b-button variant="info" v-if="data.item.station!='---'" @click="checkoutUser(data.item)">Checkout</b-button>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            users: [],
            intervall: "",
            updateIsActive: true,
            filter: null,
            loginCount: 0,
            checkinCount: 0,
            fields: [
                { key: 'nfcID', label: 'NFC', sortable: true },
                { key: 'group', label: 'Group', sortable: true },
                { key: 'name', label: 'Name', sortable: true },
                { key: 'market', label: 'Market', sortable: true },
                { key: 'station', label: 'Station', sortable: true },
                { key: 'checkout', label: '',},
            ]
        }
    },
    methods: {
        startTimer() {
            this.intervall = setInterval(function() {
                if (this.updateIsActive) {
                    this.refreshLiveUsers()
                }
            }.bind(this), 5000);
        },
        refreshLiveUsers() {
            this.kmo_refreshLiveUsers().then(() => {
                this.users = this.$store.users
                var counter = 0
                this.users.forEach(user => {
                    user['name'] = user['lastname'] + ', ' + user['firstname']
                    user['nfcID'] = user['nfcID'].substring(2)

                    switch (user['station']) {
                        case "workshop-product-1": user['station'] = "Leon Experience 1"; break;
                        case "workshop-product-2": user['station'] = "Leon Experience 2"; break;
                        case "workshop-compare-1": user['station'] = "Competitor Experience 1"; break;
                        case "workshop-compare-2": user['station'] = "Competitor Experience 2"; break;
                        case "connectivity": user['station'] = "Connectivity"; break;
                        case "leon-ar-1": user['station'] = "Assistance Systems 1"; break;
                        case "leon-ar-2": user['station'] = "Assistance Systems 2"; break;
                        case "table-customer": user['station'] = "Go electric!"; break;
                        case "accessories": user['station'] = "Leon Accessories"; break;
                        case "sportstourer": user['station'] = "Leon Sportstourer"; break;
                        default: break;
                    }

                    if (!user['station']) {
                        user['station'] = "---"
                    } else {
                        counter++
                    }
                });

                this.loginCount = this.users.length
                this.checkinCount = counter
            })
        },
        checkoutUser(user) {
            this.$bvModal.msgBoxConfirm(user.firstname + " " + user.lastname + " (" + user.nfcID + ")", {
                title: 'Checkout user?',
                size: 'sm',
                okVariant: 'danger',
                okTitle: 'CHECKOUT',
                cancelTitle: 'Cancel',
                hideHeaderClose: true,
                centered: true
            })
            .then(value => {
                if (value == true) {
                    this.kmo_checkoutUser({"userID": user.userID}).then((response) => {
                        console.log(response)
                        this.$bvToast.toast(user.firstname + " " + user.lastname + " checked out successfully", {
                            title: "Info",
                            autoHideDelay: 5000,
                            append: true
                        })
                        this.refreshLiveUsers()
                    });
                }
            })
            .catch(err => {
                console.log(err)
            });
        }
    },
    created() {
        this.updateIsActive = true
        this.refreshLiveUsers()
        this.startTimer()
    },
    beforeDestroy() {
        this.updateIsActive = false
    }
}
</script>

<style>

</style>