<template>
    <div>
        <div style="float: left"><h4>Münzen Übersicht</h4></div>
        <div style="float: right">Anzahl: <b>{{ coinList.length }}</b></div>
        <b-form-input
            v-model="filter"
            type="search"
            size="lg"
            placeholder="Suche"
        ></b-form-input><br />

        <b-spinner v-if="isLoading"></b-spinner>

        <b-list-group style="width: 350px; margin-bottom: 20px;" class="small" v-if="!isLoading">
            <b-list-group-item class="d-flex justify-content-between align-items-center py-2">
                <b-img id="coin2" src="coin0.png"></b-img>
                Nicht versuchte Challenges
                <b-badge id="color" variant="primary" pill>{{ this.coinsPossible-(this.coinsLost+this.coinsPaid+this.coinsPlayed) }}</b-badge>
            </b-list-group-item>

            <b-list-group-item class="d-flex justify-content-between align-items-center py-2">
                <b-img id="coin2" src="coin1.png"></b-img>
                Nicht bestandene Challenges
                <b-badge id="color" variant="primary" pill>{{ this.coinsLost }}</b-badge>
            </b-list-group-item>

            <b-list-group-item class="d-flex justify-content-between align-items-center py-2">
                <b-img id="coin2" src="coin2.png"></b-img>
                Bestandene Challenges
                <b-badge id="color" variant="primary" pill>{{ this.coinsPlayed+this.coinsPaid }}</b-badge>
            </b-list-group-item>

            <b-list-group-item class="d-flex justify-content-between align-items-center py-2">
                <b-img id="coin2" src="coin3.png"></b-img>
                Davon ausgezahlte Münzen
                <b-badge id="color" variant="primary" pill>{{ this.coinsPaid }}</b-badge>
            </b-list-group-item>
        </b-list-group>

        <b-table responsive small striped sort-icon-left hover :items="coinList" :fields="fields" :filter="filter" id="table" v-if="!isLoading">
            <template v-slot:cell(coin1)="{ item }">
                <div>
                    <b-img id="coin" src="coin0.png" v-if="item.coin1 == 0 || item.coin1 == undefined"></b-img>
                    <b-img id="coin" src="coin1.png" v-if="item.coin1 == 1"></b-img>
                    <b-img id="coin" src="coin2.png" v-if="item.coin1 == 2"></b-img>
                    <b-img id="coin" src="coin3.png" v-if="item.coin1 == 3"></b-img>
                </div>
            </template>
            <template v-slot:cell(coin2)="{ item }">
                <div>
                    <b-img id="coin" src="coin0.png" v-if="item.coin2 == 0 || item.coin2 == undefined"></b-img>
                    <b-img id="coin" src="coin1.png" v-if="item.coin2 == 1"></b-img>
                    <b-img id="coin" src="coin2.png" v-if="item.coin2 == 2"></b-img>
                    <b-img id="coin" src="coin3.png" v-if="item.coin2 == 3"></b-img>
                </div>
            </template>
            <template v-slot:cell(coin3)="{ item }">
                <div>
                    <b-img id="coin" src="coin0.png" v-if="item.coin3 == 0 || item.coin3 == undefined"></b-img>
                    <b-img id="coin" src="coin1.png" v-if="item.coin3 == 1"></b-img>
                    <b-img id="coin" src="coin2.png" v-if="item.coin3 == 2"></b-img>
                    <b-img id="coin" src="coin3.png" v-if="item.coin3 == 3"></b-img>
                </div>
            </template>
            <template v-slot:cell(coin4)="{ item }">
                <div>
                    <b-img id="coin" src="coin0.png" v-if="item.coin4 == 0 || item.coin4 == undefined"></b-img>
                    <b-img id="coin" src="coin1.png" v-if="item.coin4 == 1"></b-img>
                    <b-img id="coin" src="coin2.png" v-if="item.coin4 == 2"></b-img>
                    <b-img id="coin" src="coin3.png" v-if="item.coin4 == 3"></b-img>
                </div>
            </template>
            <template v-slot:cell(coin5)="{ item }">
                <div>
                    <b-img id="coin" src="coin0.png" v-if="item.coin5 == 0 || item.coin5 == undefined"></b-img>
                    <b-img id="coin" src="coin1.png" v-if="item.coin5 == 1"></b-img>
                    <b-img id="coin" src="coin2.png" v-if="item.coin5 == 2"></b-img>
                    <b-img id="coin" src="coin3.png" v-if="item.coin5 == 3"></b-img>
                </div>
            </template>
            <template v-slot:cell(coinBonus1)="{ item }">
                <div v-if="item.wave == 1">
                    <b-img id="coin" src="coin2.png" v-if="item.coinBonus1 == 2 || item.coinBonus1 == undefined"></b-img>
                    <b-img id="coin" src="coin3.png" v-if="item.coinBonus1 == 3"></b-img>
                </div>
            </template>
            <template v-slot:cell(coinBonus2)="{ item }">
                <div v-if="item.wave == 1">
                    <b-img id="coin" src="coin2.png" v-if="item.coinBonus2 == 2 || item.coinBonus2 == undefined"></b-img>
                    <b-img id="coin" src="coin3.png" v-if="item.coinBonus2 == 3"></b-img>
                </div>
            </template>
            <template v-slot:cell(coinBonus3)="{ item }">
                <div v-if="item.wave == 1">
                    <b-img id="coin" src="coin2.png" v-if="item.coinBonus3 == 2 || item.coinBonus3 == undefined"></b-img>
                    <b-img id="coin" src="coin3.png" v-if="item.coinBonus3 == 3"></b-img>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            coinList: [],
            coinSum: [],
            coinsPlayed: 0,
            coinsPaid: 0,
            coinsLost: 0,
            coinsPossible: 0,
            filter: null,
            user: {},
            isLoading: false,
            fields: [
                { key: 'eventID', label: 'Zugangscode', sortable: true },
                { key: 'name', label: 'Name', sortable: true },
                { key: 'coin1', label: 'Station1', sortable: true },
                { key: 'coin2', label: 'Station2', sortable: true },
                { key: 'coin3', label: 'Station3', sortable: true },
                { key: 'coin4', label: 'Station4', sortable: true },
                { key: 'coin5', label: 'Station5', sortable: true },
                { key: 'coinBonus1', label: 'Zusatz1', sortable: true },
                { key: 'coinBonus2', label: 'Zusatz2', sortable: true },
                { key: 'coinBonus3', label: 'Zusatz3', sortable: true },
                { key: 'timestamp', label: 'Zeitstempel', sortable: true },
            ]
        }
    },
    created() {
        this.refreshUsers()
    },
    methods: {
        refreshUsers() {
            this.isLoading = true
            this.coinsPlayed = 0
            this.coinsPaid = 0
            this.coinsLost = 0
            this.coinsPossible = 0
            this.kmo_refreshCoinList().then(() => {
                this.coinList = this.$store.coinList
                this.coinList.forEach(user => {
                    user['name'] = user['firstname'] + " " + user['lastname']
                });
                this.kmo_refreshCoinSum().then(() => {
                    this.coinSum = this.$store.coinSum
                    this.coinSum.forEach(coin => {
                        switch (coin['status']) {
                            case "0": this.coinsPossible = coin['count']; break;
                            case "1": this.coinsLost = coin['count']; break;
                            case "2": this.coinsPlayed = coin['count']; break;
                            case "3": this.coinsPaid = coin['count']; break;
                            default: break;
                        }
                    })
                    this.isLoading = false
                })
            })
        },
        resendMailCheck(user) {
            this.user = user
            this.checkShow = true
        },
        resendMail() {
            this.kmo_resendMail(this.user.userID).then(()=> {
                this.checkShow = false
            });
        }
    }
}
</script>

<style>
#table {
    background-color: white;
}
.button-primary {
    background-color: #ff8500 !important;
    border-color: #ff8500 !important;
}
#coin {
  width: 44px;
}
#coin2 {
  width: 30px;
}
</style>