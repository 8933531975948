<template>
    <div class="fixed-top">
        <b-navbar toggleable="lg" type="dark" variant="dark">
            <b-navbar-brand href="#" >CUPRA TC 2024 Backend</b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <!-- <b-navbar-nav>
                    <b-nav-item @click="showComponent('routes')">Routen</b-nav-item>
                </b-navbar-nav> -->
                <b-navbar-nav class="ml-auto">
                    <!-- <b-nav-item-dropdown text="Live" right>
                        <b-dropdown-item @click="showComponent('live-map')">Live-Map</b-dropdown-item>
                        <b-dropdown-item @click="showComponent('database-heartbeat')">Devices</b-dropdown-item>
                    </b-nav-item-dropdown> -->
                    <b-nav-item @click="showComponent('live-map')">Live-Map</b-nav-item>
                    <!-- <b-nav-item-dropdown text="Drive" right>
                        <b-dropdown-item @click="showComponent('live-map')">Live-Map</b-dropdown-item>
                        <b-dropdown-item @click="showComponent('routes')">Routes</b-dropdown-item>
                        <b-dropdown-item @click="showComponent('geofencing')">Geofencing</b-dropdown-item>
                        <b-dropdown-item @click="showComponent('podcast')">Podcast</b-dropdown-item>
                    </b-nav-item-dropdown> -->
                    <b-nav-item-dropdown text="Tracking" right>
                        <b-dropdown-item @click="showComponent('pois')">Reached-POIs</b-dropdown-item>
                        <!-- <b-dropdown-item @click="showComponent('skipped-tracks')">Skipped Tracks</b-dropdown-item>
                        <b-dropdown-item @click="showComponent('consent')">Safety-Consent</b-dropdown-item>
                        <b-dropdown-item @click="showComponent('feedback')">Feedback</b-dropdown-item> -->
                    </b-nav-item-dropdown>
                    <b-nav-item-dropdown text="Database" right>
                        <!-- <b-dropdown-item @click="showComponent('database-events')">Events</b-dropdown-item> -->
                        <b-dropdown-item @click="showComponent('database-vehicles')">Vehicles</b-dropdown-item>
                        <!-- <b-dropdown-item @click="showComponent('database-languages')">Texts</b-dropdown-item> -->
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
export default {
    methods: {
        showComponent(component) {
            this.$store.activeComponent = component
            this.$emit('showComponent')
        }
    }
}
</script>

<style>
.navbar.navbar-dark.bg-dark{
    background-color: #AA6C43 !important;
 }
</style>