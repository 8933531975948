<template>
    <div>
        <div><h4>Reached POIs</h4></div>
        <Bar
            id="chart"
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
        /><br />
        <b-table class="textTable" :items="poiList" :fields="fields" responsive bordered outlined small></b-table>
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'BarChart',
    components: {
        Bar
    },
    props: {
        chartId: {
            type: String,
            default: 'bar-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {}
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            timer: '',
            poiList: [],
            reachedPoiList: [],
            poiIDList: [],
            poiCountList: [],
            chartData: {},
            fields: [
                { key: 'index', label: 'Poi ID' },
                { key: 'poiName', label: 'Poi Name' },
                { key: 'contentKey', label: 'Podcast Episode' },
            ],
            chartOptions: {
                responsive: true, 
                maintainAspectRatio: false,
                scales: {
                    y: {
                        title: {
                            color: 'black',
                            display: true,
                            text: 'Triggers'
                        },
                        min: 0,
                        ticks: {
                            stepSize: 1
                        }
                    },
                    x: {
                        title: {
                            color: 'black',
                            display: true,
                            text: 'Poi ID'
                        }
                    }
                }
            }
        }
    },
    created() {
        this.refreshPoiList()
        this.timer = setInterval(this.triggeredAutoUpdater, 5000)
    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
    methods: {
        triggeredAutoUpdater() {
            this.refreshPoiList()
        },
        refreshPoiList() {

            this.poiCountList = []

            this.kmo_refreshPoiList().then(() => {
                this.poiList = this.$store.allPois

                this.poiIDList = []
                var index = 1
                this.poiList.forEach(poi => {
                    poi.index = index
                    this.poiIDList.push(index++)
                })

                this.kmo_refreshReachedPoiList().then(() => {
                    this.reachedPoiList = this.$store.allReachedPois

                    this.poiList.forEach(poi => {
                        var foundPoiCount = 0
                        this.reachedPoiList.forEach(poiCount => {
                            if (poi.poiID == poiCount.poiID) {
                                foundPoiCount = poiCount.count
                            }
                        })
                        this.poiCountList.push(foundPoiCount)
                    })

                    this.chartData = {
                        labels: this.poiIDList,
                        datasets: [
                            {
                                label: 'Triggers',
                                backgroundColor: '#AA6C43',
                                data: this.poiCountList
                            }
                        ]
                    }
                })
            })
        }
    }
}
</script>

<style>
#chart {
    background-color: white;
}
#color {
    background-color: #AA6C43;
    font-size: 13px;
}
.textTable {
    background-color: white;
}
</style>