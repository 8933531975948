<template>
    <div id="background">
        <header-nav @showComponent="showComponent()"></header-nav>
        <br /><br />
        <component :is="selectedComponent" style="margin-top: 20px; margin-left: 20px; margin-right: 20px;"></component>
    </div>
</template>

<script>
import Header from './components/Header'
import Routes from './components/Routes'
import Podcast from './components/Podcast'
import Geofencing from './components/Geofencing'
import POIs from './components/POIs'
import SkippedTracks from './components/SkippedTracks'
import Feedback from './components/Feedback'
import LiveMap from './components/LiveMap'
import Consent from './components/Consent'
import Coins from './components/Coins'
import ListCoin from './components/lists/ListCoin'
import ListRegister from './components/lists/ListRegister'
import ListPassed from './components/lists/ListPassed'
import ListTime from './components/lists/ListTime'
import ListQuestion from './components/lists/ListQuestion'
import UsersCheckin from './components/UsersCheckin'
import UsersPassed from './components/UsersPassed'
import UsersAchievements from './components/UsersAchievements'
import StationsStatus from './components/StationsStatus'
import SurveyVotes from './components/SurveyVotes'
import SurveyComments from './components/SurveyComments'
import Dashboard from './components/Dashboard'
import Settings from './components/Settings'
import ImportUsers from './components/importer/ImportUsers'
import ImportLanguages from './components/importer/ImportLanguages'
import ImportSubtitles from './components/importer/ImportSubtitles'
import DatabaseUsers from './components/database/DatabaseUsers'
import DatabaseLiveUsers from './components/database/DatabaseLiveUsers'
import DatabaseHeartbeat from './components/database/DatabaseHeartbeat'
import DatabaseVehicles from './components/database/DatabaseVehicles'
import DatabaseEvents from './components/database/DatabaseEvents'
import DatabaseLanguages from './components/database/DatabaseLanguages'

export default {
    data () {
        return {
            selectedComponent: "live-map"
        }
    },
    methods: {
        showComponent() {
            this.selectedComponent = this.$store.activeComponent
        }
    },
	components: {
		'header-nav': Header,
        'routes': Routes,
        'podcast': Podcast,
        'geofencing': Geofencing,
        'pois': POIs,
        'skipped-tracks': SkippedTracks,
        'feedback': Feedback,
        'live-map': LiveMap,
        'consent': Consent,
        'coins': Coins,
        'list-coin': ListCoin,
        'list-register': ListRegister,
        'list-passed': ListPassed,
        'list-time': ListTime,
        'list-question': ListQuestion,
        'users-checkin': UsersCheckin,
        'users-passed': UsersPassed,
        'users-achievements': UsersAchievements,
        'stations-status': StationsStatus,
        'survey-votes': SurveyVotes,
        'survey-comments': SurveyComments,
        'dashboard': Dashboard,
        'settings': Settings,
        'import-users': ImportUsers,
        'import-languages': ImportLanguages,
        'import-subtitles': ImportSubtitles,
        'database-users': DatabaseUsers,
        'database-liveusers': DatabaseLiveUsers,
        'database-heartbeat': DatabaseHeartbeat,
        'database-vehicles': DatabaseVehicles,
        'database-events': DatabaseEvents,
        'database-languages': DatabaseLanguages
	}
}
</script>

<style>
html, body, #background {
    background-color: #E1E1E1;
}
a:link {
  color: #AA6C43;
}
a:visited {
  color: #AA6C43;
}
a:hover {
  color: #AA6C43;
}
a:active {
  color: #AA6C43;
}
</style>
