<template>
    <div>
        <div style="float: left"><h4>Achievements</h4></div>
        <div style="float: right">Achievements: <b>{{ awardsCount }}</b></div>
        <b-form-input
            v-model="filter"
            type="search"
            size="lg"
            placeholder="Search for nfcID, name, etc."
        ></b-form-input><br />
        <b-table responsive small striped sort-icon-left hover :items="awards" :fields="fields" :filter="filter">
            <template v-slot:cell(ac001)="data"> <b-form-checkbox v-model="data.item.ac001" @input="checkboxChanged(data.item, 'ac001', data.item.ac001)"></b-form-checkbox> </template>
            <template v-slot:cell(ac002)="data"> <b-form-checkbox v-model="data.item.ac002" @input="checkboxChanged(data.item, 'ac002', data.item.ac002)"></b-form-checkbox> </template>
            <template v-slot:cell(ac003)="data"> <b-form-checkbox v-model="data.item.ac003" @input="checkboxChanged(data.item, 'ac003', data.item.ac003)"></b-form-checkbox> </template>
            <template v-slot:cell(ac004)="data"> <b-form-checkbox v-model="data.item.ac004" @input="checkboxChanged(data.item, 'ac004', data.item.ac004)"></b-form-checkbox> </template>
            <template v-slot:cell(ac005)="data"> <b-form-checkbox v-model="data.item.ac005" @input="checkboxChanged(data.item, 'ac005', data.item.ac005)"></b-form-checkbox> </template>
            <template v-slot:cell(ac006)="data"> <b-form-checkbox v-model="data.item.ac006" @input="checkboxChanged(data.item, 'ac006', data.item.ac006)"></b-form-checkbox> </template>
            <template v-slot:cell(ac007)="data"> <b-form-checkbox v-model="data.item.ac007" @input="checkboxChanged(data.item, 'ac007', data.item.ac007)"></b-form-checkbox> </template>
            <template v-slot:cell(ac010)="data"> <b-form-checkbox v-model="data.item.ac010" @input="checkboxChanged(data.item, 'ac010', data.item.ac010)"></b-form-checkbox> </template>
            <template v-slot:cell(ac011)="data"> <b-form-checkbox v-model="data.item.ac011" @input="checkboxChanged(data.item, 'ac011', data.item.ac011)"></b-form-checkbox> </template>
        </b-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            awards: [],
            intervall: "",
            updateIsActive: true,
            awardsCount: 0,
            filter: null,
            fields: [
                { key: 'nfcID', label: 'NFC', sortable: true },
                { key: 'name', label: 'Name', sortable: true },
                { key: 'ac001', label: 'Leon' },
                { key: 'ac002', label: 'Competitors' },
                { key: 'ac003', label: 'Connected' },
                { key: 'ac004', label: 'Ass.Systems' },
                { key: 'ac005', label: 'Electrified' },
                { key: 'ac006', label: 'Sportstourer' },
                { key: 'ac007', label: 'Fully equipped' },
                { key: 'ac010', label: 'Best in class' },
                { key: 'ac011', label: 'Thank you!' }
            ]
        }
    },
    methods: {
        startTimer() {
            this.intervall = setInterval(function() {
                if (this.updateIsActive) {
                    this.refreshUsersAwards()
                }
            }.bind(this), 5000);
        },
        refreshUsersAwards() {
            this.kmo_refreshUsersAwards().then(() => {
                this.awards = this.$store.awards

                this.awardsCount = 0

                this.awards.forEach(award => {
                    award['name'] = award['lastname'] + ', ' + award['firstname']
                    award['nfcID'] = award['nfcID'].substring(2)
                    award['awards'].forEach(aAward => {
                        switch (aAward) {
                            case 'ac001': award['ac001'] = true; break;
                            case 'ac002': award['ac002'] = true; break;
                            case 'ac003': award['ac003'] = true; break;
                            case 'ac004': award['ac004'] = true; break;
                            case 'ac005': award['ac005'] = true; break;
                            case 'ac006': award['ac006'] = true; break;
                            case 'ac007': award['ac007'] = true; break;
                            case 'ac010': award['ac010'] = true; break;
                            case 'ac011': award['ac011'] = true; break;
                            default: break;
                        }
                    });
                    this.awardsCount += award['awards'].length
                });
            })
        },
        checkboxChanged(user, achievementID, awarded) {

            var params = {
                "userID": user.userID,
                "achievement": achievementID,
                "awarded": awarded
            }

            this.kmo_saveAward(params).then((response) => {
                console.log(response)
            });
        }
    },
    created() {
        this.updateIsActive = true
        this.refreshUsersAwards()
        this.startTimer()
    },
    beforeDestroy() {
        this.updateIsActive = false
    }
}
</script>

<style>

</style>