<template>
    <div style="margin: 20px">
        <h2>Subtitles Importer</h2>
        <hr>
        <b-form inline>
            <b-input-group class="mb-2 mr-sm-2 mb-sm-2">
                <b-form-file 
                    v-model="file"
                    size="lg"
                    placeholder=""
                    accept=".xlsx"
                ></b-form-file>
            </b-input-group>
            <b-button class="mb-2 mr-sm-2 mb-sm-2"
                variant="primary"
                size="lg"
                @click="importSubtitles()">Import</b-button>
        </b-form>
        <hr>
    </div>
</template>

<script>
export default {
    data() {
        return {
            file: null,
        }
    },
    methods: {
        importSubtitles() {
            if (this.file != null) {
                this.kmo_importSubtitles(this.file).then((response) => {
                    this.file = null
                    this.$bvToast.toast("Successfuly imported " + response['subtitleCount'] + " subtitles", {
                        title: "Info",
                        autoHideDelay: 5000,
                        append: true
                    })
                });
            } else {
                this.$bvToast.toast("Missing XLSX file", {
                    title: "Warning",
                    variant: 'danger',
                    autoHideDelay: 5000,
                    append: true
                })
            }
        }
    }
}
</script>

<style>

</style>